import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { faSpinner, faPrint } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';

import html2pdf from 'html2pdf.js'; // Import html2pdf.js
// Register the components with Chart.js
Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function Malariagraph() {
  const [dataAvailable, setDataAvailable] = useState(true);
  const [selectedAgeRange, setSelectedAgeRange] = useState({ min: 0, max: 120 });
  const [selectedSex, setSelectedSex] = useState('Both');
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedDisease, setSelectedDisease] = useState('Malaria');
  const [barChartData, setBarChartData] = useState([]);
  const [loading, setLoading] = useState(false); // State for loading spinner
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(`${selectedYear}-01-01`);
  const [endDate, setEndDate] = useState(`${selectedYear}-12-31`);
  

  useEffect(() => {
    const token = getTokenFromUrlOrLocalStorage();
    performSecurityCheck(token);
  }, [selectedAgeRange, selectedSex, startDate, endDate, selectedYear, selectedDisease]);

  const getTokenFromUrlOrLocalStorage = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    return tokenFromUrl || localStorage.getItem('token');
  };

  const performSecurityCheck = async (token) => {
    try {
      setLoading(true); // Start loading spinner
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();
        if (securityData.message === 'Session valid') {
          fetchData(token);
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  const fetchData = async (token) => {
    try {
      setLoading(true); // Start loading spinner
      const response = await fetch(urls.diseasebargraph, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          ageRange: selectedAgeRange,
          sex: selectedSex,
          startDate,  // Include start date
          endDate,    // Include end date
          disease: selectedDisease,
        }),
      });
  
      const data = await response.json();
  
      if (data.error) {
        console.error('Error fetching data:', data.error);
        setDataAvailable(false);
      } else {
        // Initialize an array with 12 zeros, one for each month (Jan-Dec)
        const counts = new Array(12).fill(0);
  
        // Loop through the received data and populate the counts array
        data.data.forEach(item => {
          // Adjust the month (1-12) to zero-based index (0-11)
          const monthIndex = item.month - 1;
          counts[monthIndex] = item.count;
        });
  
        setBarChartData(counts);
        setDataAvailable(true);
        return data; // Return the fetched data to be used in the print function
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      setDataAvailable(false);
    } finally {
      setLoading(false); // Stop loading spinner
    }
  };
  
 
  const handleAgeRangeChange = (event) => {
    const { name, value } = event.target;
    setSelectedAgeRange({
      ...selectedAgeRange,
      [name]: parseInt(value),
    });
  };

  const handleSexChange = (event) => {
    setSelectedSex(event.target.value);
  };

  const handleYearChange = (event) => {
    const newYear = event.target.value;
    setSelectedYear(newYear);
    setStartDate(`${newYear}-01-01`);
    setEndDate(`${newYear}-12-31`);
  };
  

  const handleDiseaseChange = (event) => {
    setSelectedDisease(event.target.value);
  };

  const handlePrint = () => {
    const element = document.getElementById("bar-chart-container"); // Target the chart container
  
    const options = {
      margin: 5,  // Reduce margin to fit more content
      filename: `BarGraph_${selectedDisease}.pdf`,
      image: { type: "jpeg", quality: 1 },
      html2canvas: { scale: 1.5, useCORS: true }, // Reduce scale slightly to fit content
      jsPDF: { orientation: "landscape", unit: "mm", format: "a3", compressPDF: true }, // Use wider format
    };
  
    html2pdf().set(options).from(element).save();
  };

  const barChartDataConfig = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    datasets: [
      {
        label: `${selectedDisease} cases of ${selectedSex} sex for ${selectedAgeRange.min}-${selectedAgeRange.max} years for the year ${selectedYear}`,
    backgroundColor: '#4caf50',  // A green color for bars
    borderColor: '#388e3c',  // A darker green for borders
    borderWidth: 1,
    hoverBackgroundColor: '#81c784',  // A lighter green for hover effect
    hoverBorderColor: '#2c6e31',  // A darker green for hover border
    data: barChartData,  // Make sure barChartData is defined elsewhere
      },
    ],
  };
  
  const barChartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: true,
        text: `Disease cases of ${selectedDisease} for ${selectedAgeRange.min}-${selectedAgeRange.max} years`,
        font: {
          weight: 'bold',
          size: 16,
          color: 'black',
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: `${selectedYear} Months`,
          font: {
            weight: 'bold',
            size: 14,
            color: 'black',
          },
        },
        ticks: {
          color: 'black',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          color: 'black',
          borderColor: 'black',
          borderWidth: 1,
          lineWidth: 1,
        },
      },
      y: {
        title: {
          display: true,
          text: `${selectedDisease} cases`,
          font: {
            weight: 'bold',
            size: 14,
            color: 'black',
          },
        },
        ticks: {
          color: 'black',
          font: {
            weight: 'bold',
          },
        },
        grid: {
          color: 'black',
          borderColor: 'black',
          borderWidth: 1,
          lineWidth: 1,
        },
      },
    },
  };
  

  return (
    
    <div style={{
      fontFamily: 'Roboto, sans-serif',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      borderRadius: '0px',  // Set border radius to 0px for sharp corners
      width: '100%',  // Ensure the container covers the full width
      height: '100vh',  // Ensure the container covers the full height of the viewport
      margin: '0',  // Remove any default margin
      boxSizing: 'border-box',  // Ensure padding doesn't cause overflow
      boxShadow: 'none',  // Optionally remove any box shadow
    }}>
      <h1 style={{
        fontSize: '28px',
        fontWeight: 'bold',
        textAlign: 'center',
        color: '#333',
        marginBottom: '20px',
      }}>
        Bar Graph: {selectedDisease} cases of {selectedSex} sex aged {selectedAgeRange.min}-{selectedAgeRange.max} years for year {selectedYear}
      </h1>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
        gap: '20px',
        marginBottom: '30px',
      }}>
        {/* Select Age Range */}
        <div>
          <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Select Age Range:</label>
          <div style={{ display: 'flex', gap: '8px', marginBottom: '10px' }}>
            <input
              type="number"
              name="min"
              value={selectedAgeRange.min}
              onChange={handleAgeRangeChange}
              style={{
                padding: '10px',
                fontSize: '16px',
                borderRadius: '0px',
                border: '1px solid #ccc',
                width: '100px',
              }}
            />
            <span style={{ alignSelf: 'center', fontSize: '14px' }}>to</span>
            <input
              type="number"
              name="max"
              value={selectedAgeRange.max}
              onChange={handleAgeRangeChange}
              style={{
                padding: '10px',
                fontSize: '16px',
                borderRadius: '0px',
                border: '1px solid #ccc',
                width: '100px',
              }}
            />
          </div>
        </div>
        {/* Select Sex */}
        <div>
          <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Select Sex:</label>
          <select
            value={selectedSex}
            onChange={handleSexChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '0px',
              border: '1px solid #ccc',
              width: '100%',
            }}
          >
            <option value="Both">Both Male and Female</option>
            <option value="Male">Male</option>
            <option value="Female">Female</option>
          </select>
        </div>
       
        <div>
  <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Select Date Range:</label>
  <div style={{ display: 'flex', gap: '10px' }}>
    <input
      type="date"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
      style={{
        padding: '10px',
        fontSize: '16px',
        borderRadius: '0px',
        border: '1px solid #ccc',
        width: '180px',
      }}
    />
    <span style={{ alignSelf: 'center', fontSize: '14px' }}>to</span>
    <input
      type="date"
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
      style={{
        padding: '10px',
        fontSize: '16px',
        borderRadius: '0px',
        border: '1px solid #ccc',
        width: '180px',
      }}
    />
  </div>
</div>


        {/* Select Disease */}
        <div>
          <label style={{ fontWeight: 'bold', marginBottom: '5px' }}>Select Disease:</label>
          <select
            value={selectedDisease}
            onChange={handleDiseaseChange}
            style={{
              padding: '10px',
              fontSize: '16px',
              borderRadius: '0px',
              border: '1px solid #ccc',
              width: '100%',
            }}
          >
           <option value="Malaria">Malaria</option>
            <option value="Typhoid">Typhoid</option>
            <option value="Urinary Tract Infection">Urinary Tract Infection</option>
            <option value="Pelvic Inflammatory Disease">Pelvic Inflammatory Disease</option>
            <option value="Yellow Fever">Yellow Fever</option>
            <option value="Peptic Ulcers">Peptic Ulcers</option>
            <option value="Cough, Flu, Upper Respiratory Tract Infections">Cough, Flu, Upper Respiratory Tract Infections</option>
            <option value="Asthma">Asthma</option>
            <option value="Hypertension">Hypertension</option>
            <option value="Diabetes">Diabetes</option>
            <option value="Syphilis">Syphilis</option>
            <option value="Hernias">Hernias</option>
            <option value="Others">Others</option>
          </select>
        </div>
      </div>
    
      {/* Graph & Loading State */}
     {/* Graph & Loading State */}
<div style={{ textAlign: 'center', marginBottom: '20px' }}>
  {loading ? (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '200px',
    }}>
      <FontAwesomeIcon icon={faSpinner} spin size="5x" />
    </div>
  ) : dataAvailable ? (
    <div id="bar-chart-container" style={{ width: '100%', height: '400px' }}>
      <Bar data={barChartDataConfig} options={barChartOptions} />
    </div>
  ) : (
    <p style={{
      fontSize: '18px',
      color: '#888',
      fontStyle: 'italic',
    }}>No data found</p>
  )}
</div>

   
      {/* Print Button */}
      <button
  onClick={handlePrint} // Directly invoke the handlePrint function
  style={{
    backgroundColor: 'blue',
    color: 'white',
    border: 'none',
    padding: '12px 24px',
    borderRadius: '0px',
    cursor: 'pointer',
    fontSize: '16px',
    fontWeight: 'bold',
    transition: 'background-color 0.3s, transform 0.2s',
  }}
  onMouseOver={(e) => e.target.style.backgroundColor = '#0056b3'}
  onMouseOut={(e) => e.target.style.backgroundColor = '#007bff'}
>
  <FontAwesomeIcon icon={faPrint} style={{ marginRight: '10px' }} />
  Print Graph
</button>

    </div>
  );
  
  
  
}

export default Malariagraph;
