import React, { useState, useEffect } from 'react';
import { QRCodeCanvas } from 'qrcode.react';
import { jsPDF } from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { urls } from './config.dev';
const Receipt = ({
  receiptDetails,
  paymentAmount,
  employeeName,
  setShowReceipt,
}) => {
  const [qrCode, setQrCode] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state for QR code
  const [showSuccessMessage, setShowSuccessMessage] = useState(false); // Show payment success message
  const [reasonForPayment, setReasonForPayment] = useState('Treatment'); // Default reason for payment

  const generateQRCode = () => {
    // Check if linktoken contains "No Token"
    if (receiptDetails.linktoken && receiptDetails.linktoken.includes("No Token")) {
      // Generate QR code for suggestions
      const formattedClinicName = receiptDetails.clinicName.replace(/ /g, '%20');
      setQrCode(`https://clinicprosystem.com/submitsuggestion/${formattedClinicName}`);
    } else {
      // Generate QR code for feedback submission
      const formattedClinicName = receiptDetails.clinicName.replace(/ /g, '%20');
      const qrLink = `https://clinicprosystem.com/submitsuggestion/${formattedClinicName}/${receiptDetails.linktoken}`;
      setQrCode(qrLink);
    }
  };
  
  
  const printReceipt = () => {
    const doc = new jsPDF({ unit: 'mm', format: [80, 150] });
    doc.setFont('helvetica', 'normal');

    // Header with clinic name, address, contacts, date, and time
    const date = new Date().toLocaleString(); // Current date and time

    // Larger font for header
    doc.setFontSize(14);
    doc.text(receiptDetails.clinicName, 40, 10, { align: 'center' });

    doc.setFontSize(12);
    doc.text(`${receiptDetails.town}, ${receiptDetails.district}`, 40, 18, { align: 'center' });
    doc.text(`Contact: ${receiptDetails.ownersContact}`, 40, 25, { align: 'center' });
    doc.text(`Date: ${date}`, 40, 32, { align: 'center' });

    // Draw faint line dividing header
    doc.setDrawColor(220, 220, 220);
    doc.line(10, 37, 70, 37);

    // Body content with bold labels
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold'); // Make labels bold

    doc.text(`Client:`, 10, 43);
    doc.text(`Total Bill:`, 10, 50);
    doc.text(`Amount Paid Now:`, 10, 57);
    doc.text(`Total Paid:`, 10, 64);
    doc.text(`Balance Remaining:`, 10, 71);
    doc.text(`Reason for Payment:`, 10, 78);
    doc.text(`Served by:`, 10, 85);

    // Restore normal font for values
    doc.setFont('helvetica', 'normal');

    doc.text(`${receiptDetails.patientName}`, 45, 43);
    doc.text(`${receiptDetails.totalBill}`, 45, 50);
    doc.text(`${paymentAmount}`, 45, 57);
    doc.text(`${receiptDetails.totalPaid}`, 45, 64);
    doc.text(`${receiptDetails.balance}`, 45, 71);
    doc.text(`${reasonForPayment}`, 45, 78);
    doc.text(`${employeeName}`, 45, 85);

    // Draw faint line dividing body from footer
    doc.line(10, 90, 70, 90);

    if (qrCode) {
        const base64QR = document.getElementById('qr-code').toDataURL();
        doc.addImage(base64QR, 'PNG', 25, 95, 30, 30);
    }

    // Modify text for feedback, wrapping it if needed
    const feedbackText = "We'd love to hear your thoughts! Kindly scan the QR code and share your feedback.";
    const marginLeft = 10;
    const lineHeight = 7;
    const maxWidth = 70;

    const wrappedText = doc.splitTextToSize(feedbackText, maxWidth);

    let yPosition = 130;
    wrappedText.forEach((line) => {
        doc.text(line, marginLeft, yPosition);
        yPosition += lineHeight;
    });

    // Centered, smaller footer text
    doc.setFontSize(10);
    doc.setFont('helvetica', 'bold');
    doc.text('Thank you for your payment!', 40, yPosition + 10, { align: 'center' });

    doc.save('Receipt.pdf');
    window.open(doc.output('bloburl'), '_blank');
};

  // Use effect to generate the QR Code as soon as the component is loaded
  useEffect(() => {
    generateQRCode();
  }, [receiptDetails]);

  // Loading state control
  useEffect(() => {
    if (qrCode) {
      setLoading(false); // Hide loading when QR code is ready
    }
  }, [qrCode]);

  // Show "Payment Successful" for 1 second, then hide and show the receipt
  useEffect(() => {
    const successTimer = setTimeout(() => {
      setShowSuccessMessage(true);
    }, 500); // Delay for loading spinner to show

    const hideSuccessTimer = setTimeout(() => {
      setShowSuccessMessage(false); // Hide success message after 1 second
    }, 30000); // Keep success message visible for 1 second

    return () => {
      clearTimeout(successTimer);
      clearTimeout(hideSuccessTimer); // Cleanup timers on unmount
    };
  }, []);

  return (
    <div className="receipt-overlay" style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // Dark overlay for better readability
      zIndex: 1000,
      height: '100vh',
      overflow: 'auto',
    }}>
      {loading ? (
        <div className="loading-spinner" style={{
          textAlign: 'center',
          color: 'white',
        }}>
          {showSuccessMessage ? (
            <div>
              <p style={{ fontSize: '24px', color: 'black' }}>
                Payment Successful
                <FontAwesomeIcon icon={faCheck} style={{ marginLeft: '10px' }} />
              </p>
            </div>
          ) : null}
        </div>
      ) : (
        <div className="receipt-content" style={{
          backgroundColor: 'white',
          padding: '20px',
          width: '80%',
          maxHeight: 'calc(100vh - 40px)',
          marginTop: '20px',
          marginBottom: '20px',
          overflowY: 'auto',
          borderRadius: '0px',
          color: 'black',
        }}>
          <div className="receipt-heading" style={{
            textAlign: 'center',
            fontSize: '18px',  // Bigger font size for heading
            fontWeight: 'bold',
            marginBottom: '10px',
            color: 'black', // Ensuring black color
          }}>
            {receiptDetails.clinicName}<br />
            {receiptDetails.town}, {receiptDetails.district}<br />
            {receiptDetails.ownersContact}<br />
            <strong>{new Date().toLocaleString()}</strong>
          </div>
  
          <div className="receipt-details" style={{
  marginBottom: '10px',
  fontSize: '14px', // Smaller font size for details
  color: 'black',   // Ensuring black color
}}>
  <p><strong style={{ color: 'black' }}>Client Name:</strong> {receiptDetails.patientName}</p>
  <p><strong style={{ color: 'black' }}>Total Bill:</strong> {receiptDetails.totalBill}</p>
  <p><strong style={{ color: 'black' }}>Amount Paid Now:</strong> {paymentAmount}</p>
  <p><strong style={{ color: 'black' }}>Total Amount Paid So Far:</strong> {receiptDetails.totalPaid}</p>
  <p><strong style={{ color: 'black' }}>Balance Remaining:</strong> {receiptDetails.balance}</p>
  <p><strong style={{ color: 'black' }}>Served by:</strong> {employeeName}</p>
  <p><strong style={{ color: 'black' }}>Reason for Payment:</strong>
    <input
      type="text"
      value={reasonForPayment}
      onChange={(e) => setReasonForPayment(e.target.value)}
      style={{
        border: '1px solid #ccc',
        padding: '5px',
        width: '100%',
        fontSize: '12px',
        marginTop: '5px',
      }}
    />
  </p>
</div>

  
          <div className="receipt-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button
              onClick={() => setShowReceipt(false)}
              className="close-button"
              style={{
                backgroundColor: 'red',
                border: 'none',
                color: 'white',
                padding: '10px 20px',
                position: 'absolute',
                top: '10px',
                right: '10px',
                marginRight: '8px',
              }}
            >
              X
            </button>
          </div>
  
          <div style={{ textAlign: 'center', marginTop: '5px' }}>
            <QRCodeCanvas id="qr-code" value={qrCode} size={128} />
          </div>
          <p style={{ textAlign: 'center', marginTop: '5px' }}>This QR code is for feedback submission from patients</p>
          
          <button
            onClick={() => {
              printReceipt();
            }}
            style={{
              backgroundColor: 'blue',
              border: 'none',
              color: 'white',
              padding: '10px 20px',
              cursor: 'pointer',
              display: 'block',
              margin: '0 auto', // Centers the button horizontally
            }}
          >
            Print Receipt
          </button>
        </div>
      )}
    </div>
  );
  
};

export default Receipt;
