import React, {  useEffect, useState } from 'react';
import './LandingPage.css';
import cledImage from './images/testimony.webp';  // Importing the image
import graphImg from './images/graph.png';
import aiImg from './images/ai.png';
import dispensaryImg from './images/dispensary.png';
import dashboardImg from './images/dashboard.png';
import adminImg from './images/admin.png';
import { faFacebookSquare, faTwitterSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faReceipt,
  faBell,
  faGlobe,
  faCalendarCheck,
  faBirthdayCake,
  faUtensils,
  faCheckCircle, 
  faTimesCircle ,
  faPills,
  faHeartbeat,
  faEnvelope,
  faFileAlt,
} from "@fortawesome/free-solid-svg-icons";

const LandingPage = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
   const [isVisible, setIsVisible] = useState(false);
  const navigate = useNavigate();
  const [pointsVisible, setPointsVisible] = useState(false);
  const testimonials = [
    {
      name: "Madam Getrude",
      role: "Clinic Owner LIFE-SURE MEDICARE",
      message:
        "Clinic Pro has transformed our clinic management. It's easier to keep track of appointments and sales and expenses! 📊💼",
    },
    {
      name: "Madam Sarah",
      role: "Clinic Owner Kikajjo Health Center and Maternity Home",
      message:
        "The automated reminders and AI-powered features have saved us so much time and effort! 🤖⏳",
    },
    {
      name: "Doctor Joshua",
      role: "Clinic Administrator Asaba Medical Clinic",
      message:
        "A fantastic tool that made our clinic run more smoothly. I highly recommend it! ⭐🚀",
    },
    {
      name: "Kisaakye Jenny",
      role: "Cashier at LifeSure Medicare",
      message:
        "ClinicProSystem really saved me the burden of reminding patients of their outstanding balances. ClinicPro automatically texts patients with outstanding balances without me having to do a thing! 📱💰",
    },
    {
      name: "Ddungu Clement",
      role: "Radiographer",
      message:
        "For Obstetric Ultrasounds, I don’t have to type anymore. ClinicProSystem types everything for me. My work speed was increased by 80%! 🏥🖥️",
    },
  ];
  
  const points = [
    { text: "1. Increased Patient Satisfaction and Loyalty: By automating appointment reminders, birthday greetings, and sending personalized care messages, Clinic Pro enhances patient engagement and satisfaction, fostering loyalty and encouraging repeat visits.", icon: faCheckCircle },
    { text: "2. Better Appointment Scheduling: Clinic Pro automates appointment reminders and reduces missed appointments, leading to increased clinic capacity and fewer scheduling conflicts, directly improving patient turn-up.", icon: faCheckCircle },
    { text: "3. Enhanced Communication with Patients: Clinic Pro enables better communication through SMS and targeted messages, keeping patients informed about appointments, treatments, and health tips, which improves the overall patient experience.", icon: faCheckCircle },
    { text: "4. Improved Patient Care Through Timely Reminders: Automatic reminders for overdue treatments, antenatal visits, and tests ensure that patients stay on track with their care, preventing delays and improving health outcomes.", icon: faCheckCircle },
    { text: "5. Increased Financial Stability: By automating billing and sending reminders for payments, Clinic Pro ensures timely revenue collection and reduces outstanding debts, which enhances financial health for the clinic.", icon: faCheckCircle },
    { text: "6. Streamlined Payment Process: Clinic Pro automates the payment process, reducing administrative burden and allowing patients to pay quickly, improving both patient satisfaction and cash flow for the clinic.", icon: faCheckCircle },
    { text: "7. Personalized Patient Experience: Clinic Pro collects patient data and provides personalized health advice, treatments, and food suggestions, creating a customized experience that increases patient satisfaction and care adherence.", icon: faCheckCircle },
    { text: "8. Better Management of Chronic Conditions: Automated reminders and tailored advice from Clinic Pro help patients manage chronic conditions more effectively, ensuring that they follow prescribed treatments and attend necessary follow-ups.", icon: faCheckCircle },
    { text: "9. Increased Patient Retention: By sending personalized birthday messages and health reminders, Clinic Pro builds stronger relationships with patients, increasing their loyalty and the likelihood of returning for future care.", icon: faCheckCircle },
    { text: "10. Efficient Resource Allocation: Clinic Pro optimizes staffing and scheduling, ensuring that the right number of healthcare professionals are available to handle patient appointments, reducing waiting times and improving care delivery.", icon: faCheckCircle },
    { text: "11. Proactive Health Management: Clinic Pro helps clinics proactively monitor patient health by tracking appointments and test results, ensuring that patients receive timely care and reducing avoidable health complications.", icon: faCheckCircle },
    { text: "12. Increased Patient Turn-Up for Preventive Care: By reminding patients about preventive care such as ultrasounds, lab tests, and screenings, Clinic Pro helps clinics increase patient participation in these important health services.", icon: faCheckCircle },
    { text: "13. Better Treatment Outcomes: Clinic Pro assists doctors with drug and treatment suggestions based on patient data, helping healthcare providers make informed decisions that lead to improved patient outcomes.", icon: faCheckCircle },
    { text: "14. Greater Access to Care: Automated communication and scheduling features ensure that patients are aware of their care options, making it easier for them to access necessary treatments and services, improving patient attendance.", icon: faCheckCircle },
    { text: "15. Reduced Missed Appointments: By sending timely appointment reminders via SMS, Clinic Pro reduces the number of missed appointments, ensuring a higher patient turn-up rate and more efficient use of clinic resources.", icon: faCheckCircle },
    { text: "16. Enhanced Doctor-Patient Communication: Clinic Pro’s communication tools ensure that patients can easily reach out to their doctors, receive treatment updates, and get answers to their questions, leading to better care and patient trust.", icon: faCheckCircle },
    { text: "17. Improved Clinic Revenue: By optimizing appointment scheduling, reducing missed visits, and ensuring timely payments, Clinic Pro helps increase clinic revenue while improving operational efficiency.", icon: faCheckCircle },
    { text: "18. Faster, More Accurate Documentation: AI-assisted report writing and prescription suggestions reduce the time doctors spend on paperwork, enabling them to focus more on patient care, improving both efficiency and patient experience.", icon: faCheckCircle },
    { text: "19. Increased Patient Engagement: Clinic Pro allows patients to receive health tips, reminders, and educational content, keeping them engaged in their care journey and motivating them to follow through with treatment plans.", icon: faCheckCircle },
    { text: "20. Improved Patient Trust: With automated, personalized communication and reliable reminders, Clinic Pro helps build trust with patients by showing that the clinic is attentive to their needs and committed to their health.", icon: faCheckCircle }
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex2((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 20000); // Change every 15 seconds
  
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);
  // Countdown function
  useEffect(() => {
    let timer;
    if (isVisible && count < 20) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 100); // Speed increased to every 100ms
    }

    if (count === 20) {
      clearInterval(timer);
      setPointsVisible(true); // Start displaying points once countdown is complete
    }

    return () => clearInterval(timer);
  }, [isVisible, count]);

  // Trigger countdown when the section comes into view
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    const section = document.querySelector('.clinic-pro-value-container');
    if (section) observer.observe(section);

    return () => observer.disconnect(); // Clean up observer
  }, []);

  // To display points in groups of 2, rotating every 5 seconds
  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (pointsVisible) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 2) % points.length);
      }, 100000); // Update every 5 seconds (after 2 reasons)

      return () => clearInterval(interval);
    }
  }, [pointsVisible]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 15000); // Auto-slide every 4 seconds
    return () => clearInterval(interval);
  }, []);
  
  const screenshots = [
    {
      img: graphImg,
      title: "Graph",
      description: "The graph page in the Clinic Pro System app provides a visual representation of various diseases diagnosed and treated at your medical center per month, such as URI, ulcers, malaria, asthma, and PID. Users can adjust the year and age range to analyze trends, aiding in planning and district-level reporting. The system uses AI to accurately sort patient files, ensuring efficient disease tracking and better healthcare management."
    }
    ,
    
    {
      img: aiImg,
      title: "AI Features",
      description: "The AI in the Clinic Pro System app assists in commenting on prescriptions, helping doctors review their diagnoses and make necessary adjustments. It also suggests foods that enhance drug absorption based on the doctor's prescription. Additionally, the AI helps draw partographs for mothers in labor, explains them, and provides suggestions based on the mother's progress data."
    }
    ,
    {
      "img": dispensaryImg,
      "title": "Dispensary",
      "description": "This page functions as a pharmacy point-of-sale system, enabling clinics or medical centers to sell medicines to over-the-counter patients who may only need to purchase drugs without a consultation. It streamlines the dispensing process, ensuring accurate sales records, tracking inventory levels, and managing transactions efficiently. The system also helps pharmacists verify prescriptions, process payments, and generate receipts, improving workflow and enhancing patient convenience."
    }
    
    ,
   
      {
        img: dashboardImg,
        title: "Dashboard",
        description: "The dashboard is the main landing page after logging into the Clinic Pro System. It provides an intuitive interface where users can select their name and seamlessly navigate to various sections such as patient records, laboratory, pharmacy, billing, and reports. The dashboard enhances workflow efficiency by offering quick access to essential features, ensuring a smooth and organized experience for healthcare professionals."
      }
      
    ,
    {
      img: adminImg,
      title: "Admin Panel",
      description: "This is the landing page for administrators upon logging in. It enables the admin to view active patients—those who have visited the clinic more than once in the past six months—along with lost and inactive patients, providing insight into the clinic's performance over time. The admin can also manage employees by adding, deleting, or modifying their permissions, as well as updating passwords and making other essential changes."
    }
    ,
  ];

 

// Styles
const linkStyle = {
  textDecoration: "none",
  color: "black",
  fontWeight: 500,
};

const buttonStyle = {
  background: "transparent",
  border: "none",
  color: "black",
  fontSize: "16px",
  fontWeight: "bold",
  cursor: "pointer",
  padding: "5px 15px",
  transition: "color 0.3s ease",
};

const demoButtonStyle = {
  background: "orange",
  color: "white",
  padding: "10px 20px",
  textTransform: "uppercase",
  fontWeight: "bold",
  border: "none",
  cursor: "pointer",
  borderRadius: "5px",
  transition: "0.3s",
};
  return (
    <div className="landing-page-container">
      {/* Sticky Header Container */}
      <header
      style={{
        position: "fixed",
        top: 0,
        width: "100%",
        height: "60px", // Reduced height
        background: "white",
        boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
        zIndex: 1000,
        display: "flex",
        alignItems: "center",
      }}
    >
      <nav
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "0 20px",
        }}
      >
        {/* Logo */}
        <div style={{ fontSize: "20px", fontWeight: "bold", color: "#0066A2" }}>
          ClinicProSystem
        </div>

        {/* Navigation Links */}
        <ul
          style={{
            listStyle: "none",
            display: "flex",
            gap: "15px",
            alignItems: "center",
            padding: 0,
            margin: 0,
          }}
        >
<button
  onClick={() => window.location.href = '#pricing'} // Adds navigation behavior like the anchor tag
  style={{
    background: "none", // Ensures no background
    border: "none", // Removes any potential border
    color: "#4c2a85", // Text color
    fontSize: "15px",
    fontWeight: "bold", // Makes text bold
    fontFamily: "'Roboto', sans-serif", // A clean, modern font for buttons
    transition: "color 0.3s",
    padding: "0", // Removes padding
    cursor: "pointer", // Ensures it's clickable like a button
    textDecoration: "none", // Ensures no underline
  }}
  onMouseEnter={(e) => (e.target.style.color = "#6a3c99")} // Slightly lighter shade on hover
  onMouseLeave={(e) => (e.target.style.color = "#4c2a85")} // Original color when mouse leaves
>
  PRICING
</button>

<button
  onClick={() => window.location.href = 'aboutus'} // Adds navigation behavior like the anchor tag
  style={{
    background: "none", // Ensures no background
    border: "none", // Removes any potential border
    color: "#4c2a85", // Text color
    fontSize: "15px",
    fontWeight: "bold", // Makes text bold
    fontFamily: "'Roboto', sans-serif", // A clean, modern font for buttons
    transition: "color 0.3s",
    padding: "0", // Removes padding
    cursor: "pointer", // Ensures it's clickable like a button
    textDecoration: "none", // Ensures no underline
  }}
  onMouseEnter={(e) => (e.target.style.color = "#6a3c99")} // Slightly lighter shade on hover
  onMouseLeave={(e) => (e.target.style.color = "#4c2a85")} // Original color when mouse leaves
>
  ABOUT US
</button>

          
            <button
              style={{
                background: "#007bff", // Blue for Login
                color: "white",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
                transition: "background 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.background = "#0056b3")}
              onMouseLeave={(e) => (e.target.style.background = "#007bff")}
              onClick={() => navigate("/login")}
            >
              Login
            </button>
         
        
            <button
              style={{
                background: "#28a745", // Green for Register
                color: "white",
                border: "none",
                padding: "6px 12px",
                borderRadius: "4px",
                cursor: "pointer",
                fontSize: "14px",
                transition: "background 0.3s",
              }}
              onMouseEnter={(e) => (e.target.style.background = "#1e7e34")}
              onMouseLeave={(e) => (e.target.style.background = "#28a745")}
              onClick={() => navigate("/clinic-registration")}
            >
              Register Now
            </button>
         
        </ul>

        {/* Right Section */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <FontAwesomeIcon icon={faGlobe} style={{ fontSize: "16px", color: "#333" }} />
          <div style={{ width: "1px", height: "15px", background: "#ccc" }}></div>
          <button
            style={{
              background: "orange",
              color: "white",
              border: "none",
              padding: "6px 12px",
              borderRadius: "4px",
              cursor: "pointer",
              fontSize: "14px",
              transition: "background 0.3s",
            }}
            onMouseEnter={(e) => (e.target.style.background = "#cc8400")}
            onMouseLeave={(e) => (e.target.style.background = "orange")}
          >
            Book a Demo
          </button>
        </div>
      </nav>
    </header>
      {/* Introduction Section */}
     {/* Introduction Section */}
<section className="intro-container" style={{ 
  background: 'linear-gradient(to top, #eef2ff, white)', 
  width: '100%', 
  padding: '20px' 
}}>
  
  <div style={{ background: 'linear-gradient(to bottom, white, #eef2ff)', padding: '20px', textAlign: 'left', marginTop: '25px' }}>
  <h1 style={{ color: '#4c2a85', fontSize: '2rem' }}>What is ClinicProSystem?</h1>
    <p style={{ fontSize: '1.1rem' }}>
      {isExpanded
        ? 'ClinicProSystem is a modern, AI-powered healthcare management platform designed to revolutionize the way clinics and medical facilities operate. With its advanced AI capabilities, it optimizes everything from patient management and drug inventory tracking to lab test processing, radiology, maternity, finance management, employee performance tracking, patient feedback collections, providing insightful data for better decision-making. This innovative tool enhances communication between healthcare providers and patients, ensuring seamless connection and improving patient experience. As a new and cutting-edge solution, ClinicProSystem helps clinics deliver personalized care, reduce administrative burdens, and foster a more efficient and connected healthcare environment.'
        : 'ClinicProSystem is a modern, AI-powered healthcare management platform designed to revolutionize the way clinics and medical facilities operate. With its advanced AI capabilities, it optimizes everything from patient management and drug inventory tracking to lab test processing...'}
    </p>
    <button onClick={toggleReadMore} style={{ color: '#4c2a85', background: 'none', border: 'none', cursor: 'pointer' }}>
      {isExpanded ? 'Read Less' : 'Read More'}
    </button>
  </div>
</section>



    <section className="features-container">
  <h2 className="features-heading">
    Everything you need to manage your clinic has been digitalized here. Some of the features include:
  </h2>
  <div className="features-cards">
    {/* Existing feature cards */}
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faChartLine} />
      </div>
      <div className="text-container">
        <h3>AI Assessment of Employee Performance</h3>
        <p>Clinic Pro uses AI to assess employee performance and provide insights.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faReceipt} />
      </div>
      <div className="text-container">
        <h3>Sending of Receipts to Patients</h3>
        <p>Automatic receipt sending to patients after payments are made.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faBell} />
      </div>
      <div className="text-container">
        <h3>Automatic Reminders for Patients with Debts</h3>
        <p>Automatic reminders are sent to patients to clear outstanding balances.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faCalendarCheck} />
      </div>
      <div className="text-container">
        <h3>Appointment SMS Notifications</h3>
        <p>Automatic SMS notifications for appointments, including radiology and family planning return dates.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faBirthdayCake} />
      </div>
      <div className="text-container">
        <h3>Birthday Reminders</h3>
        <p>AI detects patient birthdays and sends automatic greetings via SMS.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faFileAlt} />
      </div>
      <div className="text-container">
        <h3>AI Assistance in Writing Reports</h3>
        <p>AI helps in writing obstetric ultrasound reports and making prescriptions.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faUtensils} />
      </div>
      <div className="text-container">
        <h3>Suggested Foods for Better Healing and Drug Absorption</h3>
        <p>Clinic Pro suggests foods that promote healing and enhance drug absorption, sending the list directly to patients via WhatsApp.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faPills} />
      </div>
      <div className="text-container">
        <h3>Drug Suggestions for Doctors</h3>
        <p>Clinic Pro suggests additional drugs to include in prescriptions based on the patient's diagnosis, signs, and symptoms, helping doctors make better decisions.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faHeartbeat} />
      </div>
      <div className="text-container">
        <h3>Automatic Detection of Ultrasound and Antenatal Visits</h3>
        <p>Clinic Pro automatically detects patients who are due for ultrasound scans and antenatal visits, sending them reminders as the date approaches.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faEnvelope} />
      </div>
      <div className="text-container">
        <h3>One-Click Messages to All or Targeted Patients</h3>
        <p>Clinic Pro enables you to write a single message that can be sent to all patients or to a specific group (e.g., Muslims or Christians), perfect for announcements like holidays or special events.</p>
      </div>
    </div>

    {/* New feature cards */}
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faFileAlt} />
      </div>
      <div className="text-container">
        <h3>Electronic Health Record Keeping</h3>
        <p>Clinic Pro stores patient health records digitally, making them easily accessible for reference and analysis.</p>
      </div>
    </div>
    <div className="feature-card">
      <div className="icon-container">
        <FontAwesomeIcon icon={faChartLine} />
      </div>
      <div className="text-container">
        <h3>AI Assists in Making District Reports</h3>
        <p>Clinic Pro uses AI to help generate detailed district reports, aiding in better health management and decision-making.</p>
      </div>
    </div>
  </div>
</section>

<section className="screenshots-container">
  {screenshots.map((screenshot, index) => (
    <div
      key={index}
      className={`screenshot-item ${index === currentIndex2 ? 'active' : ''}`}
    >
      <div className="screenshot-image">
        <img src={screenshot.img} alt={`${screenshot.title} Screenshot`} className="screenshot-img" />
      </div>
      <div className="screenshot-text">
        <h3>{screenshot.title}</h3>
        <p>{screenshot.description}</p>
      </div>
    </div>
  ))}
</section>

      {/* Clinic Pro Value Section */}
      <section className="clinic-pro-value-container" style={{
  background: 'linear-gradient(to top left, #eef2ff, white)', 
  width: '100%', 
  padding: '20px'
}}>
  <div className="countdown">{count}</div>
  {count === 20 && (
    <div className="value-text">
      Ways ClinicProSystem can add value to your Clinic/Medical Center.
    </div>
  )}

  {/* Display points as cards once countdown completes */}
  {pointsVisible && (
    <div className="points-container">
      {points.slice(currentIndex, currentIndex + 2).map((point, index) => (
        <div
          key={index}
          className="point-card"
          style={{
            animationDelay: `${index * 0.5}s`, // Delay each card's animation
          }}
        >
          <div className="icon-container">
            <i className={point.icon} />
          </div>
          {point.text}
        </div>
      ))}
    </div>
  )}
</section>
<section className="client-testimonials-container">
  <h2 style={{ color: "#0066A2" }}>Hear What Our Clients Are Saying</h2>
  <div className="testimonial-slider">
    {testimonials.map((client, index) => (
      <div
        className={`testimonial-card ${index === activeIndex ? "active" : ""}`}
        key={index}
      >
        {/* Image remains static */}
        <img src={cledImage} alt={client.name} className="testimonial-img" />
        
        {/* Only this part slides */}
        <div
          className={`testimonial-content ${
            index === activeIndex ? "active" : ""
          }`}
        >
          <p>"{client.message}"</p>
          <span>
            - {client.name}, {client.role}
          </span>
        </div>
      </div>
    ))}
  </div>
</section>

<section className="contact-section">
  <div className="contact-details">
    <h2>CONTACT US</h2>
    <p><strong>Phone Numbers: +256703521101 / +256786747733</strong></p>
    
    <p><strong>Email: clinicproug@gmail.com </strong></p>
   
  </div>
  
  <div>
    <div className="social-media">
      <p><strong>FOLLOW US:</strong></p>
      <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookSquare} style={{ color: '#3b5998' }} />
      </a>
      <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTwitterSquare} style={{ color: '#00acee' }} />
      </a>
      <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagramSquare} style={{ color: '#c13584' }} />
      </a>
      <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedin} style={{ color: '#0077b5' }} />
      </a>
    </div>

  
  </div>

  <div className="copyright">
    <p>&copy; 2025 All Rights Reserved</p>
  </div>
</section>

    
    

    </div>
  );
  
};

export default LandingPage;
