import React, { useEffect, useState } from 'react';
import './LandingPagePhones.css';
import cledImage from './images/testimony.webp';
import graphImg from './images/graph.png';
import aiImg from './images/ai.png';
import dispensaryImg from './images/dispensary.png';
import dashboardImg from './images/dashboard.png';
import adminImg from './images/admin.png';
import { faFacebookSquare, faTwitterSquare, faInstagramSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChartLine,
  faReceipt,
  faBell,
  faGlobe,
  faCalendarCheck,
  faBirthdayCake,
  faUtensils,
  faCheckCircle, 
  faTimesCircle,
  faPills,
  faHeartbeat,
  faEnvelope,
  faFileAlt,
  faBars,
  faTimes
} from "@fortawesome/free-solid-svg-icons";

const LandingPagePhones = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [count, setCount] = useState(0);
  const [activeIndex, setActiveIndex] = useState(0);
  const [currentIndex2, setCurrentIndex2] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [pointsVisible, setPointsVisible] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const testimonials = [
    {
      name: "Madam Getrude",
      role: "Clinic Owner LIFE-SURE MEDICARE",
      message: "Clinic Pro has transformed our clinic management. It's easier to keep track of appointments and sales and expenses! 📊💼",
    },
    {
      name: "Madam Sarah",
      role: "Clinic Owner Kikajjo Health Center and Maternity Home",
      message: "The automated reminders and AI-powered features have saved us so much time and effort! 🤖⏳",
    },
    {
      name: "Doctor Joshua",
      role: "Clinic Administrator Asaba Medical Clinic",
      message: "A fantastic tool that made our clinic run more smoothly. I highly recommend it! ⭐🚀",
    },
    {
      name: "Kisaakye Jenny",
      role: "Cashier at LifeSure Medicare",
      message: "ClinicProSystem really saved me the burden of reminding patients of their outstanding balances. ClinicPro automatically texts patients with outstanding balances without me having to do a thing! 📱💰",
    },
    {
      name: "Ddungu Clement",
      role: "Radiographer",
      message: "For Obstetric Ultrasounds, I don't have to type anymore. ClinicProSystem types everything for me. My work speed was increased by 80%! 🏥🖥️",
    },
  ];
  
  const points = [
    { text: "1. Increased Patient Satisfaction and Loyalty", icon: faCheckCircle },
    { text: "2. Better Appointment Scheduling", icon: faCheckCircle },
    { text: "3. Enhanced Communication with Patients", icon: faCheckCircle },
    { text: "4. Improved Patient Care Through Timely Reminders", icon: faCheckCircle },
    { text: "5. Increased Financial Stability", icon: faCheckCircle },
    { text: "6. Streamlined Payment Process", icon: faCheckCircle },
    { text: "7. Personalized Patient Experience", icon: faCheckCircle },
    { text: "8. Better Management of Chronic Conditions", icon: faCheckCircle },
    { text: "9. Increased Patient Retention", icon: faCheckCircle },
    { text: "10. Efficient Resource Allocation", icon: faCheckCircle },
  ];

  // Simplified points for mobile
  const mobilePoints = points.slice(0, 10);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex2((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 20000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    let timer;
    if (isVisible && count < 10) { // Reduced count for mobile
      timer = setInterval(() => {
        setCount((prevCount) => prevCount + 1);
      }, 100);
    }

    if (count === 10) {
      clearInterval(timer);
      setPointsVisible(true);
    }

    return () => clearInterval(timer);
  }, [isVisible, count]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.1 } // Lower threshold for mobile
    );

    const section = document.querySelector('.clinic-pro-value-container');
    if (section) observer.observe(section);

    return () => observer.disconnect();
  }, []);

  const [currentIndex, setCurrentIndex] = useState(0);
  useEffect(() => {
    if (pointsVisible) {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % mobilePoints.length); // Show one at a time on mobile
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [pointsVisible]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
    }, 15000);
    return () => clearInterval(interval);
  }, []);
  
  const screenshots = [
    {
      img: graphImg,
      title: "Graph",
      description: "Visual representation of diseases diagnosed and treated at your medical center per month."
    },
    {
      img: aiImg,
      title: "AI Features",
      description: "Assists in commenting on prescriptions and suggests foods that enhance drug absorption."
    },
    {
      img: dispensaryImg,
      title: "Dispensary",
      description: "Pharmacy point-of-sale system for selling medicines to over-the-counter patients."
    },
    {
      img: dashboardImg,
      title: "Dashboard",
      description: "Main landing page with quick access to all clinic management features."
    },
    {
      img: adminImg,
      title: "Admin Panel",
      description: "Manage employees, view patient statistics, and control clinic operations."
    }
  ];
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );
  
    const section = document.getElementById('value-section');
    if (section) observer.observe(section);
  
    return () => observer.disconnect();
  }, []);
  
  useEffect(() => {
    let timer;
    if (isVisible && count < 10) {
      timer = setInterval(() => {
        setCount(prev => prev + 1);
      }, 200); // Faster countdown
    }
  
    return () => clearInterval(timer);
  }, [isVisible, count]);
  
  useEffect(() => {
    if (count === 10) {
      setPointsVisible(true);
      const interval = setInterval(() => {
        setCurrentIndex(prev => (prev + 1) % mobilePoints.length);
      }, 10000); // Change point every 3 seconds
      
      return () => clearInterval(interval);
    }
  }, [count]);
  return (
    <div className="landing-page-container">
      {/* Mobile Header */}
      <header className="mobile-header">
        <div className="mobile-header-content">
          <div className="mobile-logo">ClinicProSystem</div>
          <button className="mobile-menu-button" onClick={toggleMobileMenu}>
            <FontAwesomeIcon icon={mobileMenuOpen ? faTimes : faBars} />
          </button>
        </div>
        
        {mobileMenuOpen && (
          <div className="mobile-menu">
            <button onClick={() => { navigate("/pricing"); toggleMobileMenu(); }}>PRICING</button>
            <button onClick={() => { navigate("/aboutus"); toggleMobileMenu(); }}>ABOUT US</button>
            <button onClick={() => { navigate("/login"); toggleMobileMenu(); }}>Login</button>
            <button onClick={() => { navigate("/clinic-registration"); toggleMobileMenu(); }}>Register Now</button>
            <button className="demo-button" onClick={toggleMobileMenu}>Book a Demo</button>
          </div>
        )}
      </header>

      {/* Introduction Section */}
      <section className="intro-container-mobile">
        <h1>What is ClinicProSystem?</h1>
        <p>
          {isExpanded
            ? 'ClinicProSystem is a modern, AI-powered healthcare management platform designed to revolutionize clinic operations. With advanced AI capabilities, it optimizes patient management, drug inventory, lab processing, and more.'
            : 'ClinicProSystem is a modern, AI-powered healthcare management platform designed to revolutionize clinic operations...'}
        </p>
        <button onClick={toggleReadMore} className="read-more-button">
          {isExpanded ? 'Read Less' : 'Read More'}
        </button>
      </section>

      {/* Features Section */}
      <section className="features-container-mobile">
        <h2>Key Features:</h2>
        <div className="features-grid-mobile">
          {[
            { icon: faChartLine, title: "AI Employee Performance", desc: "AI assesses employee performance" },
            { icon: faReceipt, title: "Digital Receipts", desc: "Automatic receipt sending to patients" },
            { icon: faBell, title: "Debt Reminders", desc: "Reminders for patients with outstanding balances" },
            { icon: faCalendarCheck, title: "Appointment SMS", desc: "Automatic SMS notifications" },
            { icon: faBirthdayCake, title: "Birthday Greetings", desc: "Automatic birthday SMS greetings" },
            { icon: faFileAlt, title: "AI Report Writing", desc: "AI assists in writing medical reports" },
            { icon: faUtensils, title: "Food Suggestions", desc: "Suggests healing-enhancing foods" },
            { icon: faPills, title: "Drug Suggestions", desc: "Suggests additional drugs for prescriptions" }
          ].map((feature, index) => (
            <div key={index} className="feature-card-mobile">
              <div className="feature-icon-mobile">
                <FontAwesomeIcon icon={feature.icon} />
              </div>
              <h3>{feature.title}</h3>
              <p>{feature.desc}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Screenshots Carousel */}
      <section className="screenshots-carousel-mobile">
        <h2>System Screenshots</h2>
        <div className="carousel-container">
          {screenshots.map((screenshot, index) => (
            <div 
              key={index} 
              className={`carousel-slide ${index === currentIndex2 ? 'active' : ''}`}
            >
              <img src={screenshot.img} alt={screenshot.title} />
              <div className="slide-text">
                <h3>{screenshot.title}</h3>
                <p>{screenshot.description}</p>
              </div>
            </div>
          ))}
        </div>
      </section>

     {/* Value Proposition Section - Fixed */}
<section className="clinic-pro-value-container-mobile" id="value-section">
  <div className="countdown-mobile">{count}</div>
  {count === 10 && (
    <div className="value-text-mobile">
      Ways ClinicProSystem Adds Value:
    </div>
  )}

  {pointsVisible && (
    <div className="points-container-mobile">
      {mobilePoints.map((point, index) => (
        <div 
          key={index}
          className={`point-card-mobile ${index === currentIndex ? 'active' : ''}`}
        >
          <FontAwesomeIcon icon={point.icon} />
          <p>{point.text}</p>
        </div>
      ))}
    </div>
  )}
</section>

      {/* Testimonials */}
      <section className="testimonials-mobile">
        <h2>Client Testimonials</h2>
        <div className="testimonial-slide-mobile">
          <img src={cledImage} alt="Testimonial" className="testimonial-img-mobile" />
          <div className="testimonial-content-mobile">
            <p>"{testimonials[activeIndex].message}"</p>
            <span>- {testimonials[activeIndex].name}, {testimonials[activeIndex].role}</span>
          </div>
        </div>
      </section>

      {/* Contact Section */}
      <section className="contact-section-mobile">
        <h2>CONTACT US</h2>
        <div className="contact-info-mobile">
          <p><strong>Phone:</strong> +256703521101 / +256786747733</p>
          <p><strong>Email:</strong> clinicproug@gmail.com</p>
        </div>
        
        <div className="social-media-mobile">
          <p><strong>FOLLOW US:</strong></p>
          <div className="social-icons-mobile">
            <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
            <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faTwitterSquare} />
            </a>
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
            <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} />
            </a>
          </div>
        </div>

        <div className="copyright-mobile">
          <p>&copy; 2025 All Rights Reserved</p>
        </div>
      </section>
    </div>
  );
};

export default LandingPagePhones;