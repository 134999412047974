import React, { useState, useEffect } from "react";
import { urls } from './config.dev'; // Importing the backend URLs
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faUserMd, faUser, faStethoscope, faMoneyBillWave, faHeartbeat, faBaby, faSyringe, faPrescriptionBottleAlt } from "@fortawesome/free-solid-svg-icons"; // Importing FontAwesome icons


const DetailedPerformanceModal = ({ employeeName, startDate, endDate, token, onClose }) => {
  const [performanceData, setPerformanceData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the detailed performance data from the backend
  useEffect(() => {
    if (employeeName && startDate && endDate) {
      const fetchPerformanceData = async () => {
        try {
          const response = await fetch(urls.fetchDetailedperformance2, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              employeeName,
              startDate,
              endDate,
              token,
            }),
          });

          if (!response.ok) {
            throw new Error('Failed to fetch performance data');
          }

          const data = await response.json();
          setPerformanceData(data);
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchPerformanceData();
    }
  }, [employeeName, startDate, endDate]);

  if (!employeeName) return null;
  const modalOverlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    overflowY: 'auto', // Ensure modal content is scrollable
  };
  
  const modalContentStyle = {
    backgroundColor: 'white',
    width: '90%', // Increased width
    height: '90vh', // Increased height
    padding: '20px',
    borderRadius: '0px', // Border radius set to 0
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    color: 'black',
    overflowY: 'auto', // Make content scrollable if necessary
    maxHeight: '90vh', // Prevents modal from exceeding viewport height
  };
  
  return (
    <div style={modalOverlayStyle}>
      <div style={modalContentStyle}>
      <button
  onClick={onClose}
  style={{
    backgroundColor: 'red', // Red background color
    border: '2px solid red', // Red border color
    borderRadius: '50%', // Make it circular
    padding: '10px', // Space inside the button
    width: '20px', // Width of the button (can adjust based on your needs)
    height: '20px', // Height of the button (same as width for a circle)
    display: 'flex', // Use flexbox to center the content inside the button
    alignItems: 'center', // Center align content vertically
    justifyContent: 'center', // Center align content horizontally
    position: 'absolute', // Optional if you need to position it at the left side
   right: '10px', // Distance from the left side of the container
  }}
>
  {/* You can add content here like an icon or text */}
  <span>x</span> {/* Example content */}
</button>


<div className="p-6">
<h2 className="text-xl font-bold text-center mb-4 mx-auto">
  Performance of <span className="uppercase">{employeeName}</span> from {startDate} to {endDate}
</h2>


  {loading && <p>Loading performance data...</p>}
  {error && <p className="text-red-500">{error}</p>}

  {performanceData && (
    <div className="mt-4">
      <h3 className="text-lg font-semibold">Statistical Performance Data:</h3>
      <ul className="list-none space-y-2">
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faCheck}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Total Days Worked: {performanceData.totalDaysWorked}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faSyringe}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Drugs Taken From Store: {performanceData.drugsTakenFromStore}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faPrescriptionBottleAlt}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Drugs Inserted Into Store: {performanceData.drugsInsertedIntoStore}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faHeartbeat}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Total Lab Tests Done: {performanceData.totalLabTestsDone}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faHeartbeat}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Total Radiology Exams Done: {performanceData.totalRadiologyExamsDone}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faMoneyBillWave}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            No of times he/she received money as cashier: {performanceData.totalReceivedMoney}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faTimes}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Total Triaged: {performanceData.totalTriaged}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faBaby}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Total Mothers Delivered: {performanceData.totalMothersDelivered}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faCheck}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Most Rated Feedback: {performanceData.mostRatedFeedback}
          </strong>
        </li>
        {/* New fields */}
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faStethoscope}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Number of Patients he/she Clerked & Prescribed Medication for: {performanceData.clerkedAndPrescribed}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faBaby}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Number of Mothers he/she Provided Antenatal Care: {performanceData.totalANCProvided}
          </strong>
        </li>
        <li style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse' }}>
          <FontAwesomeIcon
            icon={faUserMd}
            className="mr-2"
            style={{ backgroundColor: 'black', color: 'white', padding: '5px', borderRadius: '50%' }}
          />
          <strong style={{ backgroundColor: 'white', padding: '5px', borderRadius: '5px' }}>
            Number of Patients he/she provided Family Planning Methods: {performanceData.totalFamilyPlanning}
          </strong>
        </li>
      </ul>
  


  
              <div className="mt-4">
                <h3 className="text-lg font-semibold">Drug Statistics:</h3>
                <ul className="list-none space-y-2">
                  <li><strong>Total Number of times she/he administered drugs to patients:</strong> {performanceData.administeredDrugsCount}</li>
                  <li><strong>No of patients she/he administered drugs to:</strong> {performanceData.administeredDrugsPatientsCount}</li>
                  <li><strong>No of drugs she/he used when serving inpatients:</strong> {performanceData.usedDrugsCount}</li>
                  <li>
  <strong>Drugs he/she most commonly administers to inpatients:</strong> 
  {performanceData.mostUsedDrugPackagingCombination &&
  performanceData.mostUsedDrugPackagingCombination.length > 0
    ? performanceData.mostUsedDrugPackagingCombination
        .map(({ Drug, Packaging, totalQuantity }) => 
          `${totalQuantity} ${Packaging} of ${Drug}`
        )
        .join(', ')
    : '0'}
</li>
                 <li>
  <strong>Drugs he/she most commonly sells over the counter:</strong> 
  {performanceData.mostSoldDrugPackagingCombination &&
  performanceData.mostSoldDrugPackagingCombination.length > 0
    ? performanceData.mostSoldDrugPackagingCombination
        .map(({ Drug, Packaging, totalQuantity }) => 
          `${totalQuantity} ${Packaging} of ${Drug}`
        )
        .join(', ')
    : '0'}
</li>
                </ul>
              </div>
            </div>
          )}
  
          <div className="flex justify-center mt-4">
          <button
  onClick={onClose}
  style={{
    backgroundColor: 'red', // Red background color
    border: '2px solid red', // Red border color
    borderRadius: '10%', // Make it circular
    padding: '10px', // Space inside the button
    width: '50px', // Width of the button (can adjust based on your needs)
    height: '20px', // Height of the button (same as width for a circle)
    display: 'flex', // Use flexbox to center the content inside the button
    alignItems: 'center', // Center align content vertically
    justifyContent: 'center', // Center align content horizontally
    position: 'absolute', // Optional if you need to position it at the left side
   right: '10px', // Distance from the left side of the container
  }}
>
  {/* You can add content here like an icon or text */}
  <span>Close</span> {/* Example content */}
</button>
          </div>
        </div>
      </div>
    </div>
  );
  
};

export default DetailedPerformanceModal;
