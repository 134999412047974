import React, { useState, useEffect } from "react";
import { urls } from "./config.dev";

const SMSSettingsModal = ({ token, onClose }) => {
  const [smsSettings, setSmsSettings] = useState({
    billPayment: false,
    doseReminder: false,
    birthdayMessage: false,
    debtReminder: false,
    customSingle: false,
    customGroup: false,
  });

  // Fetch the SMS settings on component mount
  useEffect(() => {
    const fetchSMSSettings = async () => {
      try {
        const response = await fetch(urls.fetchSMSsettings, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token }),
        });
        const data = await response.json();
        if (response.ok) {
          // Update the state with the fetched data
          setSmsSettings({
            billPayment: data.billPayment === "1",
            doseReminder: data.doseReminder === "1",
            birthdayMessage: data.birthdayMessage === "1",
            debtReminder: data.debtReminder === "1",
            customSingle: data.customSingle === "1",
            customGroup: data.customGroup === "1",
          });
        } else {
          alert(data.error || "Failed to fetch SMS settings");
        }
      } catch (error) {
        alert("Error fetching SMS settings. Please try again.");
      }
    };

    fetchSMSSettings();
  }, [token]); // Re-fetch when token changes

  const handleChange = (event) => {
    const { name, checked } = event.target;
    setSmsSettings((prev) => ({ ...prev, [name]: checked }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(urls.submitSmsSettings, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token, smsSettings }),
      });
      const data = await response.json();
      alert(data.message || "Settings saved successfully!");
    } catch (error) {
      alert("Failed to save settings. Please try again.");
    }
  };

  return (
    <div style={overlayStyle}>
      <div style={modalStyle}>
        <h2 style={headerStyle}>SMS Settings</h2>
        <p style={nbStyle}>
          <strong>NB:</strong> SMS messages will be sent to only active clients.
        </p>
        <form onSubmit={handleSubmit} style={formStyle}>
          {smsOptions.map(({ name, label, cost }) => (
            <div key={name} style={optionContainerStyle}>
              <label style={checkboxLabelStyle}>
                <input
                  type="checkbox"
                  name={name}
                  checked={smsSettings[name]}
                  onChange={handleChange}
                  style={checkboxStyle}
                />
                {label}
              </label>
              <span style={costStyle}>UGX {cost}</span>
            </div>
          ))}
          <div style={{ display: 'flex', justifyContent: 'center', gap: '40px', marginTop: '10px' }}>
            <button type="submit" style={{ ...buttonStyle, backgroundColor: 'green', color: 'white', padding: '10px 20px' }}>
              Save Settings
            </button>
            <button onClick={onClose} style={{ ...closeButtonStyle, backgroundColor: 'red', color: 'white', padding: '10px 20px' }}>
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const smsOptions = [
  { name: "billPayment", label: "SMS after bill payment (Balance + Feedback link)-automatic", cost: 100 },
  { name: "doseReminder", label: "Reminder for next treatment dose-automatic", cost: 100 },
  { name: "birthdayMessage", label: "Birthday SMS to patients-automatic", cost: 100 },
  { name: "debtReminder", label: "Reminder for pending balances-automatic", cost: 90 },
  { name: "customSingle", label: "Custom SMS to a single patient-manual", cost: 200 },
  { name: "customGroup", label: "Custom SMS to a group of patients-manual", cost: 160 },
];

const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  background: "rgba(0, 0, 0, 0.9)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1000,
};

const modalStyle = {
  background: "#fff",
  borderRadius: "0px",
  padding: "25px",
  width: "800px",
  maxHeight: "90vh",
  overflowY: "auto",
  boxSizing: "border-box",
  fontFamily: "Arial, sans-serif",
  color: "black",
};

const headerStyle = {
  textAlign: "center",
  marginBottom: "15px",
  fontSize: "22px",
  fontWeight: "bold",
};

const nbStyle = {
  background: "#fff3cd",
  padding: "10px",
  borderRadius: "0px",
  color: "#856404",
  marginBottom: "15px",
  fontSize: "14px",
};

const formStyle = {
  display: "flex",
  flexDirection: "column",
};

const optionContainerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "8px 10px",
  borderRadius: "0px",
  marginBottom: "8px",
};

const checkboxLabelStyle = {
  display: "flex",
  alignItems: "center",
  flex: 1,
};

const checkboxStyle = {
  marginRight: "10px",
};

const costStyle = {
  fontWeight: "bold",
  textAlign: "right",
  width: "80px",
};

const buttonStyle = {};

const closeButtonStyle = {};

export default SMSSettingsModal;
