import React, { useState, useEffect } from 'react';
import { urls } from './config.dev';
import './Credits.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faTimes, faPrint, faComment, faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Topbar from './Topbar'; // Import the Topbar component
import printJS from 'print-js';
import './receiptStyles.css'; // Adjust the path as needed
import Receipt from './Receipt'; // Import the Receipt component
import LoadingState from './LoadingState';

function Credits() {
  const [employeeName, setEmployeeName] = useState('');
  const [credits, setCredits] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [showGiveCreditPrompt, setShowGiveCreditPrompt] = useState(false);
  const [newCreditAmount, setNewCreditAmount] = useState(0);
  const [selectedSuggestion, setSelectedSuggestion] = useState(null);
  const [paymentAmount, setPaymentAmount] = useState(0);
  const [showMakePaymentPrompt, setShowMakePaymentPrompt] = useState(false);
  const [message, setMessage] = useState('');
  const [showSendMessagePrompt, setShowSendMessagePrompt] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [totalCreditsGiven, setTotalCreditsGiven] = useState(0);
  const [totalPaidAmount, setTotalPaidAmount] = useState(0);
  const [sumRemainingBalance, setSumRemainingBalance] = useState(0);
  const [confirmingCredit, setConfirmingCredit] = useState(false); // New state for confirming credit
  const navigate = useNavigate();
  const [isSubmittingPayment, setIsSubmittingPayment] = useState(false); // New state for payment submission
  const [token, setToken] = useState('');
  const [canSendReminder, setCanSendReminder] = useState(false); // State to control reminder button
  const [clinicName, setClinicName] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token'); // Define urlToken here
  const [receiptDetails, setReceiptDetails] = useState(null);
  const [showReceipt, setShowReceipt] = useState(false);
  const [reason, setReason] = useState('Treatment');
  const [district, setDistrict] = useState('');
const [ownersContact, setOwnersContact] = useState('');
const [town, setTown] = useState('');

  useEffect(() => {
    // Function to extract token from URL query parameters
    const extractTokenFromUrl = () => {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
      if (tokenFromUrl) {
        // Set token state and also store in localStorage for persistent storage
        setToken(tokenFromUrl);
        localStorage.setItem('token', tokenFromUrl);
      } else {
        console.error('No token found in URL');
        // Handle case where token is not present in URL (redirect to login, show error, etc.)
      }
    };

    extractTokenFromUrl();
  }, []);

  useEffect(() => {
    // Function to extract token from URL query parameters
    const extractTokenFromUrl = () => {
      const params = new URLSearchParams(window.location.search);
      return params.get('token');
    };
  
    // Fetch employee name, credit summary, and messaging permission
    const fetchEmployeeNameCreditSummaryAndPermission = async (token) => {
      try {
        // Fetch security details
        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ token }), // Send the token received as parameter
        });
  
        if (securityResponse.ok) {
          const securityData = await securityResponse.json();
  
         if (securityData.message === 'Session valid') {
        setEmployeeName(securityData.employee_name);
        setClinicName(securityData.clinic); // Set the clinic name
        setDistrict(securityData.district); // Set the district
        setOwnersContact(securityData.owners_contact); // Set the owner's contact
        setTown(securityData.town); // Set the town
        localStorage.setItem('token', securityData.clinic_session_token);
  
            // Fetch credit summary with the token included in the request payload
            const creditSummaryResponse = await fetch(urls.creditsum, {
              method: 'POST', // Ensure the method is POST if you are sending a payload
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({ token }), // Include token in the request payload
            });
            
            if (creditSummaryResponse.ok) {
              const creditSummaryData = await creditSummaryResponse.json();
              setTotalCreditsGiven(parseFloat(creditSummaryData.totalCreditsGiven).toFixed(2));
              setTotalPaidAmount(parseFloat(creditSummaryData.totalPaidAmount).toFixed(2));
              setSumRemainingBalance(parseFloat(creditSummaryData.sumRemainingBalance).toFixed(2));
            } else {
              throw new Error('Failed to fetch credit summary');
            }
  
            // Fetch messaging permission
            await fetchMessagingPermission(token);
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };
  
    // Initialize token from URL or localStorage
    const tokenFromUrl = extractTokenFromUrl();
    if (tokenFromUrl) {
      // Set token state and also store in localStorage for persistent storage
      setToken(tokenFromUrl);
      localStorage.setItem('token', tokenFromUrl);
  
      // Fetch employee name, credit summary, and messaging permission using the extracted token
      fetchEmployeeNameCreditSummaryAndPermission(tokenFromUrl);
    } else {
      console.error('No token found in URL');
      // Handle case where token is not present in URL (redirect to login, show error, etc.)
      navigate('/login');
    }
  }, [navigate]);
  
  
  
  useEffect(() => {
    const fetchCredits = async () => {
      try {
        // Extract token from URL query parameters
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
  
        // Check if token from URL exists
        if (!tokenFromUrl) {
          throw new Error('Token not found in URL');
        }
  
        const response = await fetch(urls.fetchcredits, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            token: tokenFromUrl,
          }),
        });
  
        if (response.ok) {
          const data = await response.json();
          setCredits(data);
        } else {
          throw new Error('Failed to fetch credits');
        }
      } catch (error) {
        console.error('Error fetching credits:', error.message);
      }
    };
  
    // Initial fetch
    fetchCredits();
  
    // Fetch credits every 5 seconds
    const intervalId = setInterval(fetchCredits, 5000);
  
    // Cleanup function to clear interval
    return () => clearInterval(intervalId);
  }, []); // Dependency array is empty assuming token retrieval is handled outside this hook
  
  const fetchMessagingPermission = async (token) => {
    try {
      const response = await fetch(urls.messagingPermission, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.messages === 'yes') {
          setCanSendReminder(true); // Enable button if permission is granted
        } else {
          setCanSendReminder(false); // Disable button if permission is not granted
        }
      } else {
        throw new Error('Failed to fetch messaging permission');
      }
    } catch (error) {
      console.error('Error fetching messaging permission:', error.message);
    }
  };

  
  const handleGiveCreditButtonClick = () => {
    setShowGiveCreditPrompt(true);
  };

  const handleMakePaymentButtonClick = (credit) => {
    setSelectedSuggestion(credit);
    setShowMakePaymentPrompt(true);
  };

  const handleSendMessageButtonClick = () => {
    setShowSendMessagePrompt(true);
  };

  const handleGiveCredit = async () => {
    try {
      if (!selectedSuggestion) {
        throw new Error('No user selected');
      }
      setConfirmingCredit(true); // Start confirming credit
  
      // Extract token from URL query parameters
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      // Check if token from URL exists
      if (!tokenFromUrl) {
        throw new Error('Token not found in URL');
      }
  
      const payload = {
        user: selectedSuggestion,
        amount: newCreditAmount,
        token: tokenFromUrl, // Include token from URL in the payload
      };
  
      const response = await fetch(urls.confirmcredit, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        // If the response is successful, display success message
        alert('Credit confirmed successfully');
        // Reset input fields
        setNewCreditAmount(0);
        // Close the give credit prompt
        setShowGiveCreditPrompt(false);
        // Optionally, you may reload the page or fetch updated data
        // window.location.reload();
      } else {
        // If the response is not successful, throw an error
        throw new Error('Failed to confirm credit');
      }
    } catch (error) {
      console.error('Error confirming credit:', error.message);
    } finally {
      setConfirmingCredit(false); // End confirming credit
    }
  };
  const handleConfirmPayment = async () => {
    try {
      if (!selectedSuggestion || !paymentAmount || paymentAmount <= 0) {
        throw new Error("Invalid payment details");
      }
  
      if (paymentAmount > selectedSuggestion.balance_remaining) {
        throw new Error("Payment amount exceeds remaining balance");
      }
  
      setIsSubmittingPayment(true);
  
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get("token");
  
      if (!tokenFromUrl) {
        throw new Error("Token not found in URL");
      }
  
      const payload = {
        employeeName: employeeName,
        patientId: selectedSuggestion.credit_id,
        patientName: `${selectedSuggestion.first_name} ${selectedSuggestion.last_name}`,
        phoneNumber: selectedSuggestion.phone_number,
        religion: selectedSuggestion.religion,
        dob: selectedSuggestion.dob,
        age: selectedSuggestion.age,
        sex: selectedSuggestion.sex,
        amount: paymentAmount,
        token: tokenFromUrl,
      };
  
      const response = await fetch(urls.submitpayment, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      let linktoken = "no token"; // Default value if no token is received
      let balanceRemaining = selectedSuggestion.balance_remaining; // Use the balance from selectedSuggestion
  
      // Fetch link token regardless of payment response
      const linkTokenPayload = { patientId: selectedSuggestion.credit_id };
      try {
        const linkTokenResponse = await fetch(urls.fetchlinktoken2, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(linkTokenPayload),
        });
  
        if (linkTokenResponse.ok) {
          const linkTokenData = await linkTokenResponse.json();
          if (linkTokenData.token) {
            linktoken = linkTokenData.token;
          }
          // If balance_remaining is provided in the response, use it
          if (linkTokenData.balance_remaining) {
            balanceRemaining = linkTokenData.balance_remaining;
          }
        }
      } catch (error) {
        console.error("Error fetching link token:", error);
      }
  
      // Send the fetched link token and balance_remaining to SendPaymentSms
      const smsPayload = {
        ...payload,
        linktoken, // Send the fetched token (or 'no token')
        balanceRemaining, // Send the balance remaining from the response
      };
  
      try {
        await fetch(urls.SendPaymentSms, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(smsPayload),
        });
      } catch (error) {
        console.error("Error sending payment SMS:", error);
      }
  
      // Convert amounts to numbers for accurate calculations
      const totalPaid = parseFloat(selectedSuggestion.money_paid) + parseFloat(paymentAmount);
      const balance = parseFloat(selectedSuggestion.balance_remaining) - parseFloat(paymentAmount);
  
      setReceiptDetails({
        patientId: selectedSuggestion.credit_id,
        patientName: `${selectedSuggestion.first_name} ${selectedSuggestion.last_name}`,
        clinicName: clinicName,
        district: district,
        town: town,
        ownersContact: ownersContact,
        reason,
        totalBill: Math.round(parseFloat(selectedSuggestion.credit_given)),
        totalPaid: Math.round(totalPaid),
        balance: Math.round(balance),
        linktoken, // Include the fetched link token in receipt details
      });
  
      setShowMakePaymentPrompt(false);
      setShowReceipt(true); // Open receipt no matter the response
    } catch (error) {
      console.error("Error confirming payment:", error);
    } finally {
      setIsSubmittingPayment(false);
    }
  };
  
  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleSuggestionClick = (suggestion) => {
    setSelectedSuggestion(suggestion);
    setShowGiveCreditPrompt(true);
  };

  const handleMakePayment = (patientDetails) => {
    setSelectedSuggestion(patientDetails);
    setShowMakePaymentPrompt(true);
  };

  const handleCancel = () => {
    setShowGiveCreditPrompt(false);
    setShowMakePaymentPrompt(false);
    setShowSendMessagePrompt(false);
    setSelectedSuggestion(null);
    setMessage('');
  };

  const handleSendMessage = (credit) => {
    const phoneNumber = credit.phone;
    const message = `Hello our esteemed Client, warm greetings from ${clinicName}. You are kindly reminded to clear your outstanding balance of UGX ${credit.balance_remaining}, medical bill that has been due since ${credit.date_credit_given}, in order to enable us efficient service delivery. Thank you. We wish you good health.`;
    setMessage(message);
    setSelectedSuggestion(credit);
    setShowSendMessagePrompt(true);
  };

  const handleSendReminder = async () => {
    setIsLoading(true);
    try {
      if (!selectedSuggestion) {
        throw new Error('No selected suggestion to send reminder');
      }

      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');

      const payload = {
        phoneNumber: selectedSuggestion.phone_number,
        message: message,
        token: tokenFromUrl
      };

      const response = await fetch(urls.whatsappall, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const responseData = await response.json();
        const messages = responseData.messages || [];

        const sentNumbers = messages.filter(result => result.status.description === 'Message sent to next instance').map(result => result.to);
        const failedNumbersNotRegistered = messages.filter(result => result.status.description === 'Destination not registered').map(result => result.to);
        const failedNumbersUnknownReason = messages.filter(result => result.status.description !== 'Message sent to next instance' && result.status.description !== 'Destination not registered').map(result => result.to);

        const successCount = sentNumbers.length;
        const notRegisteredCount = failedNumbersNotRegistered.length;
        const unknownReasonCount = failedNumbersUnknownReason.length;

        let toastMessage = '';

        if (successCount > 0) {
          const charge = successCount * 30; // Charge is $30 per successful message
          toastMessage += `${successCount} messages sent successfully to the following numbers:\n${sentNumbers.join(', ')}\n\n`;
          toastMessage += `You have been charged UGX ${charge}.\n\n`;
        } else {
          toastMessage += `No successful messages were sent. You have not been charged.\n\n`;
        }

        if (notRegisteredCount > 0) {
          toastMessage += `${notRegisteredCount} messages failed to send because the numbers are not registered on WhatsApp:\n${failedNumbersNotRegistered.join(', ')}\n\n`;
        }

        if (unknownReasonCount > 0) {
          toastMessage += `${unknownReasonCount} messages failed to send for unknown reasons:\n${failedNumbersUnknownReason.join(', ')}`;
        }

        if (toastMessage) {
          toast.success(toastMessage, {
            position: "top-right",
            autoClose: 15000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }

      
        console.log('Reminder sent successfully');
      } else {
        toast.error(`Failed to send reminder: ${response.statusText}`, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        console.error('Failed to send reminder:', response.statusText);
      }
    } catch (error) {
      toast.error(`Error sending reminder: ${error.message}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      console.error('Error sending reminder:', error);
    }

    setIsLoading(false);
    setMessage('');
    setShowSendMessagePrompt(false);
  };
  
  const handleSuggestName = async (name) => {
    try {
      // Retrieve token from URL parameters
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      // Construct query string including the token
      const queryString = `?name=${encodeURIComponent(name)}&token=${encodeURIComponent(tokenFromUrl)}`;
  
      // Make the fetch request with the constructed URL
      const response = await fetch(`${urls.suggest}${queryString}`);
  
      if (response.ok) {
        const data = await response.json();
        if (data.message && data.message === 'No patient records found for the specified clinic') {
          // If no patient records are found, update the state to display the message
          setSuggestions([]);
          setMessage('No patient records found');
        } else {
          // If patient records are found, update the suggestions state
          setSuggestions(data);
          setMessage(''); // Clear any previous error message
        }
      } else {
        throw new Error('Failed to suggest names');
      }
    } catch (error) {
      console.error('Error suggesting names:', error.message);
    }
  };
  
  return (
    <div>
      {isLoading && <LoadingState />}
      <Topbar token={urlToken} />
      <ToastContainer />
      <div style={{ paddingTop: '70px' }}> {/* This style moves the search bar a few centimeters from the top */}
        <input
          type="text"
          placeholder="Search credited Patient by name..."
          value={searchTerm}
          onChange={handleSearch}
          style={{ marginBottom: '10px', padding: '10px', width: '100%', maxWidth: '400px', border: '1px solid #ccc', borderRadius: '4px' }} // Additional styling for the search bar
        />
        {message && <div style={{ marginBottom: '10px', color: 'red' }}>{message}</div>}
        <p>Total Credits/Patient bills : UGX {totalCreditsGiven}</p>
        <p>Total Paid Amount: UGX {totalPaidAmount}</p>
        <p>Sum of Remaining Balances: UGX {sumRemainingBalance}</p>

        <button onClick={handleGiveCreditButtonClick}>
          <FontAwesomeIcon icon={faCreditCard} className="yellow-icon" /> Give New Credit/Creat a new Patient Bill
        </button>

      </div>
      {showGiveCreditPrompt && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="transaction-prompt">
              <h2>Give Credit</h2>
              {selectedSuggestion ? (
                <div>
                  <p>Name: {selectedSuggestion.first_name} {selectedSuggestion.last_name}</p>
                  <p>Phone: {selectedSuggestion.phone_number}</p>
                  <p>Religion: {selectedSuggestion.religion}</p>
                  <p>Date of Birth: {selectedSuggestion.dob}</p>
                  <p>Age: {selectedSuggestion.age}</p>
                  <p>Sex: {selectedSuggestion.sex}</p>
                  {/* Display other details */}
                </div>
              ) : (
                <input
                  type="text"
                  placeholder="Enter name"
                  onChange={(e) => handleSuggestName(e.target.value)}
                />
              )}
              <input
                type="number"
                value={newCreditAmount}
                onChange={(e) => setNewCreditAmount(parseFloat(e.target.value))}
                placeholder="Enter amount"
              />
              <button onClick={handleGiveCredit} disabled={confirmingCredit}>
                <FontAwesomeIcon icon={faCreditCard} />
                {confirmingCredit ? 'Please wait...' : 'Confirm'}
              </button>
              <button onClick={handleCancel}>Cancel</button>
              {suggestions.map((suggestion, index) => (
                <div key={index} onClick={() => handleSuggestionClick(suggestion)}>
                  {suggestion.first_name} {suggestion.last_name}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      {showMakePaymentPrompt && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="transaction-prompt">
              <h2>Make Payment</h2>
              <p>How much is {selectedSuggestion?.first_name} paying?</p>
              <input
                type="number"
                value={paymentAmount}
                onChange={(e) => setPaymentAmount(parseFloat(e.target.value))}
                placeholder="Enter amount"
              />
              <button onClick={handleConfirmPayment} disabled={isSubmittingPayment}>
                <FontAwesomeIcon icon={faMoneyCheckAlt} />
                {isSubmittingPayment ? 'Please wait...' : 'Submit Payment'}
              </button>
              <button onClick={handleCancel}>Cancel</button>
            </div>
          </div>
        </div>
      )}
 {showReceipt && receiptDetails && (
        <Receipt
          receiptDetails={receiptDetails}
         
          paymentAmount={paymentAmount}
          employeeName={employeeName}
       
          setShowReceipt={setShowReceipt}
        />
      )}



      {showSendMessagePrompt && (
  <div className="modal-overlay">
    <div className="modal-content">
      <h2>Send Reminder</h2>
      {isLoading ? (
        <LoadingState />
      ) : (
        <>
          <p>{message}</p>
          <button onClick={handleSendReminder}>Send Reminder</button>
          <button onClick={handleCancel}>Cancel</button>
        </>
      )}
    </div>
  </div>
)}

      <div>
        <h2>Below are patient bills / credits</h2>
        <div className="credits-container">
          {credits
            .filter((credit) =>
              credit.first_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              credit.last_name.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map((credit, index) => (
              <div key={index} className="credit-box">
                <p>Name: {credit.first_name} {credit.last_name}</p>
                <p>Phone: {credit.phone_number}</p>
                <p>Religion: {credit.religion}</p>
                <p>Date of Birth: {credit.dob}</p>
                <p>Age: {credit.age}</p>
                <p>Sex: {credit.sex}</p>
                <p>Credit Given: {credit.credit_given}</p>
                <p>Money Paid: {credit.money_paid}</p>
                <p>Balance Remaining: {credit.balance_remaining}</p>
                <p>Date Credit Given: {credit.date_credit_given}</p>
                <p>Date Last Payment: {credit.date_last_payment}</p>

                {/* Render credit details */}
                <button onClick={() => handleMakePayment(credit)}>
                  <FontAwesomeIcon icon={faMoneyCheckAlt} className="yellow-icon" /> Make Payment
                </button>
                {/*<button onClick={() => handleSendMessage(credit)} disabled={!canSendReminder}>
                  <FontAwesomeIcon icon={faComment} className="yellow-icon" /> Send Whatsapp Reminder
                </button>*/}
              </div>
            ))}
        </div>
      </div>
      <footer className="footer2">
        This system was created by DeepMind E-Systems. For help Contact us +256786747733 
      </footer>
    </div>
  );

}

export default Credits;
