import React, {useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faProcedures,
  faCapsules,
  faMoneyBillWave,
  faChartLine,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { urls } from "./config.dev"; // Importing URLs from config.dev
import { ClipLoader } from "react-spinners"; // Install with `yarn add react-spinners`
import SettingsModal from './SettingsModal'; // Adjust the path as needed
import SMSSettingsModal from './SMSSettingsModal'; // Adjust the path as needed
import InactivePatientsModal from './InactivePatientsModal'; // Adjust the path based on your file structure

// Registering the necessary chart components for Chart.js
ChartJS.register(ArcElement, Tooltip, Legend);

const AdminDashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true); // Loading state
  const [employeeName, setEmployeeName] = useState("");
  const [token, setToken] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [clinicSessionToken, setClinicSessionToken] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [district, setDistrict] = useState("");
  const chartRef = useRef(null);
  const [showModal2, setShowModal2] = useState(false);
  const [showModal3, setShowModal3] = useState(false);
  const [dashboardData, setDashboardData] = useState({
    employees: 0,
    activePatients: 0,
    inactivePatients: 0,
    lostClients: 0,
    drugsWorth: 0,
    dispensaryWorth: 0,
    stockWorth: 0,
  });
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get("token");
  // Token Security Check on Page Load
  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get("token");
        console.log("URL Token:", tokenFromUrl);

        const securityResponse = await fetch(urls.security, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ token: tokenFromUrl }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === "Session valid") {
            setEmployeeName(securityData.employee_name);
            setClinicSessionToken(securityData.clinic_session_token);
            setClinicName(securityData.clinic);  // Set clinic name
            setDistrict(securityData.district);  // Set district

            // Fetch the admin dashboard data
            fetchAdminDashboardData(tokenFromUrl);
          } else if (securityData.error === "Session expired") {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate("/login");
          }
        } else {
          throw new Error("Failed to perform security check");
        }
      } catch (error) {
        console.error("Error performing security check:", error);
        navigate("/login");
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate]);


 
   const fetchAdminDashboardData = async (token) => {
     try {
       const response = await fetch(urls.fetchAdminData, {
         method: "POST",
         headers: {
           "Content-Type": "application/json",
         },
         body: JSON.stringify({ token }),
       });
 
       if (response.ok) {
         const data = await response.json();
         console.log("Admin Dashboard Data:", data);
 
         // Rounding off the values to the nearest whole number
         setDashboardData({
           employees: data.employee_count,
           activePatients: data.active_patients_count,
           inactivePatients: data.inactive_patients_count,
           lostClients: data.lost_clients_count,
           drugsWorth: Math.round(data.total_worth),
           dispensaryWorth: Math.round(data.dispensary_worth),
           stockWorth: Math.round(data.stock_worth),
           revenue: Math.round(data.revenue), // Round revenue to nearest whole number
         });
         setLoading(false); // Data fetched, stop loading
       } else {
         throw new Error("Failed to fetch admin data");
       }
     } catch (error) {
       console.error("Error fetching admin dashboard data:", error);
       setLoading(false); // Stop loading even if there was an error
     }
   };
 
   useEffect(() => {
     // Check if token is available
     if (tokenFromUrl) {
       fetchAdminDashboardData(tokenFromUrl); // Fetch data when the token is available
     } else {
       console.error("Token is missing in the URL");
       setLoading(false); // Stop loading if token is missing
     }
   }, [tokenFromUrl]); // Depend on tokenFromUrl to trigger the fetch
 
   if (loading) {
     return (
       <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
         <ClipLoader size={50} color="#3182ce" />
       </div>
     );
   }
   const handleChartClick = (event) => {
    // Fetch the token from the URL inside the function
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get("token");

    if (tokenFromUrl) {
      setToken(tokenFromUrl);
      console.log("URL Token:", tokenFromUrl);
    }

    if (!chartRef.current) return;

    const chartInstance = chartRef.current;
    const activePoints = chartInstance.getElementsAtEventForMode(
      event,
      "nearest",
      { intersect: true },
      true
    );

    if (activePoints.length > 0) {
      const datasetIndex = activePoints[0].datasetIndex;
      const dataIndex = activePoints[0].index;

      // Check if the clicked segment is "Inactive Patients"
      if (datasetIndex === 0 && dataIndex === 1) {
        setShowModal3(true);
      }
    }
  };


  const patientData = {
    labels: ["Active Patients", "Inactive Patients", "Lost Clients"],
    datasets: [
      {
        data: [
          dashboardData.activePatients,
          dashboardData.inactivePatients,
          dashboardData.lostClients,
        ],
        backgroundColor: ["#38a169", "#ed8936", "#f56565"],
        borderWidth: 1,
      },
    ],
  };

  // Doughnut Chart Data for drug stock
  const drugStockData = {
    labels: ["Dispensary Worth", "Store Worth"],
    datasets: [
      {
        data: [dashboardData.dispensaryWorth, dashboardData.stockWorth],
        backgroundColor: ["#00C49F", "#FFBB28"],
        borderWidth: 1,
      },
    ],
  };
// Extract token from URL when the component mounts
const handleShowModal = () => {
  // Fetch token from URL
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get('token');
  
  if (tokenFromUrl) {
    setToken(tokenFromUrl);
    console.log('URL Token:', tokenFromUrl);
  }

  // Show the modal
  setShowModal(true);
};
{showModal && <SettingsModal token={token} onClose={() => setShowModal(false)} />}
// Extract token from URL when the component mounts
const handleShowModal2 = () => {
  // Fetch token from URL
  const params = new URLSearchParams(window.location.search);
  const tokenFromUrl = params.get('token');
  
  if (tokenFromUrl) {
    setToken(tokenFromUrl);
    console.log('URL Token:', tokenFromUrl);
  }

  // Show the modal
  setShowModal2(true);
};

 
  return (
    <div style={{ display: "flex", height: "100vh", backgroundColor: "white", color: "black" }}>
      {/* Sidebar */}
      <aside
        style={{
          width: "220px",
          backgroundColor: "#f0f0f0",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          boxShadow: "2px 0 5px rgba(0, 0, 0, 0.1)",
          overflowY: "auto",
        }}
      >
        {/* Clinic Name Display */}
        <div style={{
          fontSize: "18px",
          fontWeight: "bold",
          color: "#333",
          marginBottom: "20px",
          textAlign: "center",
          borderBottom: "2px solid #ddd",
          paddingBottom: "10px",
        }}>
          {clinicName}
        </div>
        <nav style={{ display: "flex", flexDirection: "column", gap: "15px" }}>
  {[
    { icon: faUsers, label: "Employees", subMenu: ["Employee Settings"] },
    { icon: faProcedures, label: "Patients", subMenu: [] },
    { icon: faCapsules, label: "Inventory", subMenu: [] },
    { icon: faMoneyBillWave, label: "Billing", subMenu: ["Make Payment", "Payment History", "Bill Expenditure"] },
    { icon: faChartLine, label: "Reports", subMenu: ["Weekly Reports", "Monthly Reports"] },
    { icon: faCog, label: "Settings", subMenu: ["Change Password", "SMS Settings"] },
  ].map((item, index) => (
    <div key={index} style={{ display: "flex", flexDirection: "column" }}>
      <a
        href="#"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "10px",
          color: "black",
          textDecoration: "none",
          transition: "background-color 0.3s",
          padding: "8px 12px",
          borderRadius: "4px",
        }}
        onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#ff8c00")}
        onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
      >
        <FontAwesomeIcon icon={item.icon} />
        <span>{item.label}</span>
      </a>

      {/* SubMenu */}
      {item.subMenu.length > 0 && (
        <div style={{ marginLeft: "20px", marginTop: "10px", fontSize: "14px" }}>
          {item.subMenu.map((subItem, subIndex) => (
            <a
              key={subIndex}
              href="#"
              style={{
                display: "block",
                color: "black",
                textDecoration: "none",
                padding: "5px 0",
                transition: "background-color 0.3s",
                paddingLeft: "10px",
              }}
              onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#ff8c00")}
              onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "transparent")}
              onClick={() => {
                if (subItem === "Employee Settings") {
                  navigate(`/employeesettings?token=${tokenFromUrl}`);
                } else if (subItem === "Change Password") {
                  handleShowModal();
                } else if (subItem === "SMS Settings") {
                  handleShowModal2();
                }
              }}
            >
              {subItem}
            </a>
          ))}
        </div>
      )}
    </div>
  ))}

  {/* Modals */}
  {showModal && <SettingsModal token={token} onClose={() => setShowModal(false)} />}
  {showModal2 && <SMSSettingsModal token={token} onClose={() => setShowModal2(false)} />}
  {showModal3 && <InactivePatientsModal token={token} onClose={() => setShowModal3(false)} />}
</nav>;
      </aside>
  
      {/* Main Content */}
      <main style={{ flex: 1, padding: "30px", width: "calc(100% - 220px)", overflowY: "auto" }}>
      {/* Metrics Section */}
      <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: "20px" }}>
        {[{ icon: faUsers, label: "Employees", value: dashboardData.employees, color: "#3182ce" },
          { icon: faProcedures, label: "Active Patients", value: dashboardData.activePatients, color: "#ed8936" },
          { icon: faCapsules, label: "Drugs Worth", value: `UGX ${dashboardData.drugsWorth}`, color: "#38a169" },
          { icon: faMoneyBillWave, label: "Monthly Revenue", value: `UGX ${dashboardData.revenue}`, color: "#ecc94b" }
        ].map((metric, index) => (
          <div
            key={index}
            style={{
              backgroundColor: "white",
              padding: "20px",
              borderRadius: "0px",
              display: "flex",
              alignItems: "center",
              gap: "10px",
              boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.2)",
              color: "black",
            }}
          >
            <FontAwesomeIcon icon={metric.icon} style={{ fontSize: "28px", color: metric.color }} />
            <div>
              <h3 style={{ fontSize: "16px", fontWeight: "bold", textTransform: "uppercase" }}>
                {metric.label}
              </h3>
              <p style={{ fontSize: "20px", fontWeight: "normal" }}>
                {metric.value}
              </p>
            </div>
          </div>
        ))}
      </div>

      {/* Doughnut Charts */}
      <div style={{ marginTop: "20px", padding: "0px", backgroundColor: "#f5f5f5", borderRadius: "0px" }}>
      <div style={{ display: "flex", gap: "20px", justifyContent: "space-around" }}>
        <div style={{ flex: "1", maxWidth: "400px" }}>
          <h3 style={{ textAlign: "center", fontSize: "18px", color: "#333", marginBottom: "20px" }}>
            Patient Distribution
          </h3>
          <Doughnut ref={chartRef} data={patientData} onClick={handleChartClick} />
        </div>
      
          <div style={{ flex: "1", maxWidth: "400px" }}>
            <h3 style={{ textAlign: "center", fontSize: "18px", color: "#333", marginBottom: "20px" }}>
              Drug Stock Worth
            </h3>
            <Doughnut data={drugStockData} />
          </div>
        </div>
      </div>

      {/* Key at the bottom */}
      <div style={{
        marginTop: "40px",
        padding: "20px",
        backgroundColor: "#f9f9f9",
        borderTop: "2px solid #ddd",
        fontSize: "14px",
      }}>
        <p><strong>Active Clients:</strong> Patients who have visited the clinic multiple times within the last 6 months, or those who had a single visit within the last 4 months.</p>
        <p><strong>Inactive Clients:</strong> Patients who visited the clinic between 6-12 months ago, or those who visited once within the past 4-8 months.</p>
        <p><strong>Lost Clients:</strong> Patients who have not visited the clinic for over 12 months, either with a single or multiple visits in the past.</p>
      </div>
    </main>
    </div>
  );
  
};

export default AdminDashboard;
