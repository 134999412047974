import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import './contacts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { faComment, faWallet } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoadingState from './LoadingState';
import Topbar from './Topbar'; // Import the Topbar component
import AddToWalletPrompt from './AddToWalletPrompt'; // Assuming this is the prompt component

function Contacts() {
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [newContactVisible, setNewContactVisible] = useState(false);
  const [sendMessageVisible, setSendMessageVisible] = useState(false);
  const [newContact, setNewContact] = useState({ first_name: '', last_name: '', phone_number: '', age: '', sex: '', dob: '', religion: '', address: '' });
 const [message, setMessage] = useState('');
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [showSendMessagePrompt, setShowSendMessagePrompt] = useState(false);
  const [showAddContactPrompt, setShowAddContactPrompt] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [showSendMessageToAllPrompt, setShowSendMessageToAllPrompt] = useState(false);
  const [messageToAllPatients, setMessageToAllPatients] = useState('');
  const [messageToAllPatientsMuslims, setMessageToAllPatientsMuslims] = useState('');
  const [messageToAllPatientsChristians, setMessageToAllPatientsChristians] = useState('');
  const [employeeName, setEmployeeName] = useState(''); // State for employee name
  const [isAddingContact, setIsAddingContact] = useState(false); // State to track add contact process
  const [editContactVisible, setEditContactVisible] = useState(false); // State for showing edit contact prompt
const [editedContact, setEditedContact] = useState({}); // State for edited contact details
const [clinicSessionToken, setClinicSessionToken] = useState(''); // State to store clinic session token
const [token, setToken] = useState('');
const navigate = useNavigate();
const [canSendReminder, setCanSendReminder] = useState(false); // State to control reminder button
const [isLoading, setIsLoading] = useState(false);
const params = new URLSearchParams(window.location.search);
const urlToken = params.get('token'); // Define urlToken here
const [isWalletPromptOpen, setIsWalletPromptOpen] = useState(false);
const [selectedContactId, setSelectedContactId] = useState(null);


useEffect(() => {
  const fetchTokenAndCheckSecurity = async () => {
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');

      console.log('URL Token:', tokenFromUrl);

      // Perform security check using the token
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: tokenFromUrl }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();

        if (securityData.message === 'Session valid') {
          setEmployeeName(securityData.employee_name);

          console.log('Clinic Session Token:', securityData.clinic_session_token);

          setClinicSessionToken(securityData.clinic_session_token);

          if (securityData.clinic_session_token) {
            setToken(securityData.clinic_session_token);
            fetchContacts(securityData.clinic_session_token);
            fetchMessagingPermission(); // Call the function here
          }
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    }
  };

  fetchTokenAndCheckSecurity();
}, [navigate]);

const fetchContacts = async (token) => {
  try {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    const response = await fetch(urls.fetchcontacts, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenFromUrl }),
    });

    if (response.ok) {
      const data = await response.json();
      const sortedContacts = data.sort((a, b) => a.first_name.localeCompare(b.first_name));
      setContacts(sortedContacts);
      setFilteredContacts(sortedContacts);
    } else {
      console.error('Failed to fetch contacts:', response.statusText);
    }
  } catch (error) {
    console.error('Error fetching contacts:', error);
  }
};

const fetchMessagingPermission = async () => {
  try {
    // Fetch token from URL parameter
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');

    if (!tokenFromUrl) {
      throw new Error('Token not found in URL parameters');
    }

    const response = await fetch(urls.messagingPermission, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ token: tokenFromUrl }),
    });

    if (response.ok) {
      const data = await response.json();
      if (data.messages === 'yes') {
        setCanSendReminder(true); // Enable button if permission is granted
      } else {
        setCanSendReminder(false); // Disable button if permission is not granted
      }
    } else {
      throw new Error('Failed to fetch messaging permission');
    }
  } catch (error) {
    console.error('Error fetching messaging permission:', error.message);
  }
};

  const editContact = (contact) => {
    setEditedContact(contact);
    setEditContactVisible(true);
  };
  
  const updateContact = async () => {
    setIsAddingContact(true); // Start add or update contact process
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const response = await fetch(urls.editcontact, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...editedContact,
          contact_id: editedContact.contact_id, // Include the contact ID in the payload
          token: tokenFromUrl, // Include the token from URL in the payload
        }),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        setEditContactVisible(false);
        fetchContacts(tokenFromUrl);
        alert(responseData.message); // Display success message from backend
      } else {
        console.error('Failed to edit contact:', response.statusText);
      }
    } catch (error) {
      console.error('Error editing contact:', error);
    }
  
    setIsAddingContact(false); // End add or update contact process
  };
  
  
  const addContact = async () => {
    // Validate that all fields are filled
    if (!newContact.first_name || !newContact.last_name || !newContact.phone_number || !newContact.age || !newContact.sex || !newContact.dob || !newContact.religion) {
      alert('Please fill in all fields');
      return;
    }
  
    setIsAddingContact(true); // Start add contact process
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get('token');
  
      const response = await fetch(urls.addcontact, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...newContact,
          token: tokenFromUrl
        }),
      });
  
      const responseData = await response.json();
  
      if (response.ok) {
        setShowAddContactPrompt(false);
        fetchContacts(tokenFromUrl);
        alert(responseData.message); // Display success message from backend
      } else {
        console.error('Failed to add contact:', response.statusText);
      }
    } catch (error) {
      console.error('Error adding contact:', error);
    }
  
    setIsAddingContact(false); // End add contact process
  };
  
  const removeContact = async (contactId, phoneNumber) => {
    if (window.confirm('Are you sure you want to remove this contact?')) {
      try {
        const params = new URLSearchParams(window.location.search);
        const tokenFromUrl = params.get('token');
  
        const response = await fetch(urls.removecontact, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            contactId,
            phoneNumber,
            token: tokenFromUrl
          }),
        });
  
        if (response.ok) {
          // Remove the contact from the state
          const updatedContacts = contacts.filter(contact => contact.contact_id !== contactId);
          setContacts(updatedContacts);
          setFilteredContacts(updatedContacts);
          alert('Contact removed successfully');
        } else {
          console.error('Failed to remove contact:', response.statusText);
        }
      } catch (error) {
        console.error('Error removing contact:', error);
      }
    }
  };
  
  const sendMessage = async (phoneNumber) => {
    setShowSendMessagePrompt(true);
    setPhoneNumber(phoneNumber); // Set phoneNumber in component state
  };

 
  
  const sendActualMessage = async (phoneNumber) => {
    setIsLoading(true); // Start loading
  
    try {
      // Fetch token from URL parameter
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get("token");
  
      // Calculate message length and cost
      const messageLength = message.length;
      let costPerSms = messageLength * 0.5;
  
      // Ensure cost per SMS is at least 60 UGX
      if (costPerSms < 60) {
        costPerSms = 60;
      }
  
      const totalCost = costPerSms;
  
      const confirmationMessage = `
        📢 **Message Sending Summary**
        ------------------------------------
        💬 Message Length: **${messageLength} characters**
        💰 Estimated Cost: **UGX ${totalCost.toLocaleString()}**
  
        🚫 Note: Ensure your wallet balance is sufficient.
        ➡️ Press **OK** to continue sending message.
      `;
  
      if (!window.confirm(confirmationMessage)) {
        setIsLoading(false);
        setShowSendMessageToAllPrompt(false); // Hide the prompt if canceled
        return;
      }
  
      const response = await fetch(urls.whatsappall2, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          phoneNumber: phoneNumber,
          message: message,
          estimatedCost: totalCost, // Include the estimated cost in the payload
          token: tokenFromUrl, // Include the token in the payload
        }),
      });
  
      const data = await response.json(); // Parse the JSON response
  
      if (data.message === "SMS sent successfully") {
        toast.success("📩 SMS sent successfully!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("❌ Failed to send SMS. Try again.", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error("❌ An error occurred. Please try again.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  
  
  
  const sendMessageToAllPatients = (religion) => {
    setShowSendMessageToAllPrompt(true);
    setNewContact({ ...newContact, religion: religion }); // Update newContact state with religion
  };
 {/*} const sendMessageToAllPatients2 = () => {
    setShowSendMessageToAllPrompt(true);
    
    // Set newContact state to both Islamic and Christian
    setNewContact({
      ...newContact,
      religion: ['Islamic', 'Christian'],  // Set both religions
    });
  };*/}
  
  const sendToAllPatients = async () => {
    setIsLoading(true);
  
    try {
      const params = new URLSearchParams(window.location.search);
      const tokenFromUrl = params.get("token");
  
      const allNumbers = contacts
        .filter(contact => contact.religion === newContact.religion)
        .map(contact => contact.phone_number);
  
      const validNumbersSet = new Set();
      const invalidNumbers = [];
  
      allNumbers.forEach(number => {
        if (/^07\d{8}$/.test(number) || /^2567\d{8}$/.test(number)) {
          validNumbersSet.add(number);
        } else {
          invalidNumbers.push(number);
        }
      });
  
      const validNumbers = Array.from(validNumbersSet);
      const invalidCount = invalidNumbers.length;
      const validCount = validNumbers.length;
  
      const messageContent = newContact.religion === "Islamic" ? messageToAllPatientsMuslims : messageToAllPatientsChristians;
      const messageLength = messageContent.length;
      let costPerSms = messageLength * 0.5;
  
      // Ensure cost per SMS is at least 60 UGX
      if (costPerSms < 60) {
        costPerSms = 60;
      }
  
      const totalCost = costPerSms * validCount;
  
      let maxCost = null;
      const wantsToSetLimit = window.confirm("Do you want to set a maximum cost for this action?");
      
      if (wantsToSetLimit) {
        let inputAmount = prompt("Enter the maximum amount you want to spend (UGX):", "");
        if (inputAmount && /^\d+$/.test(inputAmount)) {
          maxCost = parseInt(inputAmount, 10);
        }
      }
  
      const confirmationMessage = `
        📢 **Message Sending Summary**
        ------------------------------------
        ❌ ${invalidCount} phone numbers are invalid.
        ✅ ${validCount} valid phone numbers found.
        📝 Message Length: **${messageLength} characters**
        💰 Estimated Cost: **UGX ${totalCost.toLocaleString()}**
        ${maxCost ? `🔹 Maximum Spending Limit: **UGX ${maxCost.toLocaleString()}**` : ""}
        
        🚫 Note: Inactive patients will **not** receive the SMS, reducing the final cost.
        ⚠️ Ensure your wallet balance is sufficient.
        ➡️ Press **OK** to continue sending messages.
      `;
  
      if (!window.confirm(confirmationMessage)) {
        setIsLoading(false);
        return;
      }
  
      const payload = {
        phoneNumbers: validNumbers,
        message: messageContent,
        token: tokenFromUrl,
      };
      
      if (maxCost !== null) {
        payload.maxCost = maxCost;
      }
  
      const response = await fetch(urls.whatsappall, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });
  
      if (response.ok) {
        const responseData = await response.json();
        displayNotification(responseData);
      } else {
        toast.error(`❌ Failed to send messages: ${response.statusText}`, {
          position: "top-right",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error(`❌ Error: ${error.message}`, {
        position: "top-right",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  
    setIsLoading(false);
    setShowSendMessageToAllPrompt(false);
};

const displayNotification = (data) => {
  const { success_count, current_balance, sent_names, total_deduction_cost } = data;

  // Use total_deduction_cost from the response instead of calculating cost based on success_count
  const cost = total_deduction_cost;

  // Generate recipient list with each name on a new line
  const recipientsList = sent_names.map((name, index) => (
      <div key={index}>📌 {name}</div>
  ));

  // Create a custom overlay behind the toast
  const overlayStyle = {
      position: "fixed",
      top: "0",
      left: "0",
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",  // Semi-transparent black overlay
      zIndex: "999",  // Ensures the overlay is behind the toast
  };

  // Toast content styling
  const toastStyle = {
      maxHeight: "400px",
      overflowY: "auto",
      whiteSpace: "pre-line",
      marginTop: "50px",
      width: "1000px",
  };

  // Show toast with the overlay effect
  toast.success(
      <div style={{ ...toastStyle, position: 'relative' }}>
          <p>✅ {success_count} messages sent.</p>
          <p>💰 Cost: <strong>UGX {cost.toLocaleString()}</strong></p>
          <p>🏥 Balance: <strong>UGX {current_balance.toLocaleString()}</strong></p>
          <hr />
          <strong>📋 Recipients:</strong>
          <div>{recipientsList}</div>
      </div>,
      {
          position: "bottom-center",  // Centers the toast on the screen
          autoClose: false,  // Disables auto-closing, requires manual dismissal
          closeOnClick: true,  // Allows manual closing
          draggable: true,  // Can be dragged
          hideProgressBar: false,  // Shows progress bar for better visibility
          pauseOnHover: true,  // Stays open when hovered
          onOpen: () => {
              // Add overlay when toast opens
              const overlay = document.createElement('div');
              Object.assign(overlay.style, overlayStyle); // Apply styles
              document.body.appendChild(overlay);
          },
          onClose: () => {
              // Remove overlay when toast closes
              const overlay = document.querySelector('[style*="background-color: rgba(0, 0, 0, 0.5)"]');
              if (overlay) overlay.remove();
          },
          onExited: () => {
              // Automatically close after 5 seconds if no user interaction
              const autoCloseTimeout = setTimeout(() => {
                  toast.dismiss();  // Dismiss the toast automatically after 5 seconds
              }, 5000);  // 5 seconds delay

              // Reset timeout if user hovers or scrolls
              const toastElement = document.querySelector('.Toastify');
              toastElement.addEventListener('mouseover', () => {
                  clearTimeout(autoCloseTimeout);  // Reset timeout on hover
              });
              toastElement.addEventListener('wheel', () => {
                  clearTimeout(autoCloseTimeout);  // Reset timeout on scroll
              });
          },
      }
  );
};

  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    filterContacts(query);
  };

  const filterContacts = (query) => {
    if (!query) {
      setFilteredContacts(contacts);
    } else {
      const filteredContacts = contacts.filter(contact =>
        contact.first_name.toLowerCase().startsWith(query.toLowerCase())
      );
      setFilteredContacts(filteredContacts);
    }
  };
  const handleAddToWalletClick = (contactId) => {
    setSelectedContactId(contactId); // Set the selected contact ID
    setIsWalletPromptOpen(true); // Open the modal
  };
  
  const inputStyle = {
    width: "100%",
    padding: "8px",
    marginTop: "5px",
    border: "1px solid #ccc",
    borderRadius: "0px",
    fontSize: "16px",
  };
  
  const buttonStyle = {
    padding: "10px 15px",
    backgroundColor: "#007BFF",
    color: "white",
    border: "none",
    borderRadius: "0px",
    cursor: "pointer",
    fontSize: "16px",
  };
  
  return (
    <div>
          
       
      <ToastContainer />
<div className="contacts-scrollable-container"
  style={{
    backgroundColor: "white", // Light beige color
    padding: "10px",
    borderRadius: "8px"
  }}
>
<Topbar token={urlToken} />
<h1 className="page-title" style={{ marginTop: '60px' }}>ALL CLIENT DETAILS</h1>

<div
  style={{
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    backgroundColor: "#f8f9fa",
    borderRadius: "0px",
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
    width: "100%",
    maxWidth: "400px",
    margin: "20px auto",
  }}
>
  <input
    type="text"
    placeholder="Search contact by name"
    value={searchQuery}
    onChange={handleSearchChange}
    style={{
      width: "100%",
      padding: "10px",
      fontSize: "16px",
      border: "1px solid #ccc",
      borderRadius: "5px",
      outline: "none",
      transition: "border-color 0.3s",
    }}
    onFocus={(e) => (e.target.style.borderColor = "#007bff")}
    onBlur={(e) => (e.target.style.borderColor = "#ccc")}
  />
</div>


  {showSendMessagePrompt && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="transaction-prompt">
        <h2>Send Message</h2>
        <textarea
  rows="4"
  cols="50"
  placeholder="Type your message here"
  value={message}
  onChange={(e) => setMessage(e.target.value)}
  style={{
    fontFamily: '"Roboto", sans-serif', // Use Roboto or any other good font
    fontSize: '14px', // Optional: you can adjust the font size as needed
    padding: '10px', // Optional: padding to make the text area look better
    borderRadius: '4px', // Optional: a little border-radius for smooth edges
    border: '1px solid #ccc' // Optional: a light border color
  }}
></textarea>


          <div className="transaction-buttons">
            {isLoading ? (
              <LoadingState />
            ) : (
              <>
              <button 
  onClick={() => setShowSendMessagePrompt(false)} 
  style={{ backgroundColor: 'red', color: 'white', border: 'none', borderRadius: '0px', padding: '10px' }}
>
  Cancel
</button>

<button 
  onClick={() => sendActualMessage(phoneNumber)} 
  style={{ backgroundColor: 'green', color: 'white', border: 'none', borderRadius: '0px', padding: '10px', marginLeft: '10px' }}
>
  Send
</button>

            </>
          )}
        </div>
      </div>
    </div>
  </div>
)}

     {showSendMessageToAllPrompt && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="transaction-prompt">
        <h2>Send Message to All {newContact.religion} Patients</h2>
        <textarea
  rows="4"
  cols="50"
  placeholder="Type your message here"
  value={newContact.religion === 'Islamic' ? messageToAllPatientsMuslims : messageToAllPatientsChristians}
  onChange={(e) =>
    newContact.religion === 'Islamic'
      ? setMessageToAllPatientsMuslims(e.target.value)
      : setMessageToAllPatientsChristians(e.target.value)
  }
  style={{
    fontFamily: '"Roboto", sans-serif', // Using Roboto font
    fontSize: '14px', // Optional: adjust the font size as needed
    padding: '10px', // Optional: adds padding for better appearance
    borderRadius: '4px', // Optional: rounded corners
    border: '1px solid #ccc' // Optional: light border color
  }}
></textarea>


        <div className="transaction-buttons">
          {isLoading ? (
            <LoadingState />
          ) : (
            <>
              <button 
  onClick={() => setShowSendMessageToAllPrompt(false)} 
  style={{ 
    backgroundColor: 'red', 
    color: 'white', 
    border: 'none', 
    borderRadius: '0px', 
    padding: '10px' 
  }}
>
  Cancel
</button>

<button 
  onClick={sendToAllPatients} 
  style={{ 
    backgroundColor: 'green', 
    color: 'white', 
    border: 'none', 
    borderRadius: '0px', 
    padding: '10px', 
    marginLeft: '10px' 
  }}
>
  Send to All {newContact.religion} Patients
</button>

            </>
          )}
        </div>
      </div>
    </div>
  </div>
)}

{showAddContactPrompt && (
  <div
    className="modal-overlay"
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 1000,
    }}
  >
    <div
      className="modal-content"
      style={{
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "10px",
        width: "400px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div className="transaction-prompt">
        <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
          Add New Contact
        </h2>

        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          <label>
            First Name:
            <input
              type="text"
              value={newContact.first_name}
              onChange={(e) =>
                setNewContact({ ...newContact, first_name: e.target.value })
              }
              className="add-contact-input"
              style={inputStyle}
            />
          </label>

          <label>
            Last Name:
            <input
              type="text"
              value={newContact.last_name}
              onChange={(e) =>
                setNewContact({ ...newContact, last_name: e.target.value })
              }
              className="add-contact-input"
              style={inputStyle}
            />
          </label>

          <label>
            Phone Number:
            <input
              type="text"
              value={newContact.phone_number}
              onChange={(e) =>
                setNewContact({
                  ...newContact,
                  phone_number: e.target.value.replace(/\D/, ""),
                })
              }
              className="add-contact-input"
              style={inputStyle}
            />
          </label>

          <label>
            Address:
            <input
              type="text"
              value={newContact.address}
              onChange={(e) =>
                setNewContact({ ...newContact, address: e.target.value })
              }
              className="add-contact-input"
              style={inputStyle}
            />
          </label>

          <label>
            Age:
            <input
              type="number"
              value={newContact.age}
              onChange={(e) =>
                setNewContact({
                  ...newContact,
                  age: e.target.value.replace(/\D/, ""),
                })
              }
              className="add-contact-input"
              style={inputStyle}
            />
          </label>

          <label>
            Sex:
            <select
              value={newContact.sex}
              onChange={(e) =>
                setNewContact({ ...newContact, sex: e.target.value })
              }
              className="add-contact-input"
              style={inputStyle}
            >
              <option value="">Select Sex</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
            </select>
          </label>

          <label>
            Date of Birth:
            <input
              type="text"
              placeholder="YYYY-MM-DD"
              value={newContact.dob}
              onChange={(e) =>
                setNewContact({ ...newContact, dob: e.target.value })
              }
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              className="add-contact-input"
              style={inputStyle}
            />
          </label>

          <label>
            Religion:
            <select
              value={newContact.religion}
              onChange={(e) =>
                setNewContact({ ...newContact, religion: e.target.value })
              }
              className="add-contact-input"
              style={inputStyle}
            >
              <option value="">Select Religion</option>
              <option value="Christian">Christian</option>
              <option value="Islamic">Islamic</option>
            </select>
          </label>
        </div>

        <div
  className="transaction-buttons"
  style={{
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  }}
>
  <button
    onClick={() => setShowAddContactPrompt(false)}
    style={{
      ...buttonStyle,
      backgroundColor: "red",
      color: "white",
      borderRadius: "0px", // Zero radius
    }}
  >
    Cancel
  </button>
  <button
    onClick={addContact}
    disabled={isAddingContact}
    style={{
      ...buttonStyle,
      backgroundColor: "green",
      color: "white",
      borderRadius: "0px", // Zero radius
    }}
  >
    {isAddingContact ? "Please wait..." : "Add Contact"}
  </button>
</div>

      </div>
    </div>
  </div>
)}

<div className="custom-button-container" style={{ display: 'flex', alignItems: 'center' }}>
  <button 
    onClick={() => sendMessageToAllPatients('Islamic')} 
    disabled={!canSendReminder}
    style={{ 
      backgroundColor: 'blue', 
      color: 'white', 
      borderRadius: '0px', 
      padding: '10px 15px', 
      border: 'none', 
      cursor: canSendReminder ? 'pointer' : 'not-allowed', 
      opacity: canSendReminder ? 1 : 0.6,
      marginRight: '10px' // Adds spacing between buttons
    }}
  >
    <FontAwesomeIcon icon={faComment} className="yellow-icon" /> SMS to Muslims
  </button>
  <button 
    onClick={() => setShowAddContactPrompt(true)} 
    style={{ 
      backgroundColor: 'green', 
      color: 'white', 
      borderRadius: '0px', 
      padding: '10px 15px', 
      border: 'none', 
      cursor: 'pointer',
      marginRight: '10px' // Adds spacing between buttons
    }}
  >
    Add New Patient/Client
  </button>
  <button 
    onClick={() => sendMessageToAllPatients('Christian')} 
    disabled={!canSendReminder}
    style={{ 
      backgroundColor: 'blue', 
      color: 'white', 
      borderRadius: '0px', 
      padding: '10px 15px', 
      border: 'none', 
      cursor: canSendReminder ? 'pointer' : 'not-allowed', 
      opacity: canSendReminder ? 1 : 0.6
    }}
  >
    <FontAwesomeIcon icon={faComment} className="yellow-icon" /> SMS to Christians
  </button>
</div>


  <div className="custom2-button-container">
<div className="add-contact-section">


</div>
</div>

<ul className="contacts-list">
  {filteredContacts.map((contact) => (
    <li key={contact.contact_id} className="contact-item">
      {/* Existing patient details */}
      <p>{contact.first_name} {contact.last_name}</p>
      <p>Phone: {contact.phone_number}</p>
      <p>Age: {contact.age}</p>
      <p>Sex: {contact.sex}</p>
      <p>Date of Birth: {contact.dob}</p>
      <p>Religion: {contact.religion}</p>
      <p>Address: {contact.address}</p>
      
      <button 
        onClick={() => editContact(contact)} 
        disabled={isAddingContact}
        style={{ 
          backgroundColor: 'green', 
          color: 'white', 
          borderRadius: '0px', 
          padding: '10px 15px', 
          border: 'none', 
          cursor: isAddingContact ? 'not-allowed' : 'pointer', 
          opacity: isAddingContact ? 0.6 : 1 
        }}
      >
        <FontAwesomeIcon icon={faEdit} /> Edit Details
      </button>

      <button 
        onClick={() => sendMessage(contact.phone_number)} 
        disabled={!canSendReminder}
        style={{ 
          backgroundColor: 'blue', 
          color: 'white', 
          borderRadius: '0px', 
          padding: '10px 15px', 
          border: 'none', 
          cursor: canSendReminder ? 'pointer' : 'not-allowed', 
          opacity: canSendReminder ? 1 : 0.6 
        }}
      >
        <FontAwesomeIcon icon={faComment} className="yellow-icon" /> Send SMS
      </button>

      <button 
        onClick={() => removeContact(contact.contact_id, contact.phone_number)} 
        disabled={isAddingContact}
        style={{ 
          backgroundColor: 'red', 
          color: 'white', 
          borderRadius: '0px', 
          padding: '10px 15px', 
          border: 'none', 
          cursor: isAddingContact ? 'not-allowed' : 'pointer', 
          opacity: isAddingContact ? 0.6 : 1 
        }}
      >
        <FontAwesomeIcon icon={faTrash} /> Delete Patient
      </button>

      {/* Add to Pro-Wallet Button */}
      <button
        onClick={() => handleAddToWalletClick(contact.contact_id)} 
        style={{ 
          backgroundColor: 'purple', 
          color: 'white', 
          borderRadius: '0px', 
          padding: '10px 15px', 
          border: 'none', 
          cursor: 'pointer'
        }}
      >
        <FontAwesomeIcon icon={faWallet} /> Add to Pro-Wallet Mobile App
      </button>
    </li>
  ))}
</ul>

{/* Render the AddToWalletPrompt modal */}
{isWalletPromptOpen && (
  <AddToWalletPrompt
    contactId={selectedContactId}
    onClose={() => setIsWalletPromptOpen(false)} // Close the modal
  />
)}

      </div>
      {editContactVisible && (
  <div className="modal-overlay">
    <div className="modal-content">
      <div className="transaction-prompt">
      <h2 style={{ textAlign: "center", marginBottom: "20px" }}>
      Edit Contact Details
    </h2>
    
    {/* First Name */}
    <label>First Name</label>
    <input
      type="text"
      value={editedContact.first_name}
      onChange={(e) =>
        setEditedContact({ ...editedContact, first_name: e.target.value })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    />
    
    {/* Last Name */}
    <label>Last Name</label>
    <input
      type="text"
      value={editedContact.last_name}
      onChange={(e) =>
        setEditedContact({ ...editedContact, last_name: e.target.value })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    />
    
    {/* Phone Number */}
    <label>Phone Number</label>
    <input
      type="text"
      value={editedContact.phone_number}
      onChange={(e) =>
        setEditedContact({
          ...editedContact,
          phone_number: e.target.value.replace(/\D/, ""),
        })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    />
    
    {/* Address */}
    <label>Address</label>
    <input
      type="text"
      value={editedContact.address}
      onChange={(e) =>
        setEditedContact({ ...editedContact, address: e.target.value })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    />
    
    {/* Age */}
    <label>Age</label>
    <input
      type="number"
      value={editedContact.age}
      onChange={(e) =>
        setEditedContact({
          ...editedContact,
          age: e.target.value.replace(/\D/, ""),
        })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    />
    
    {/* Sex */}
    <label>Sex</label>
    <select
      value={editedContact.sex}
      onChange={(e) =>
        setEditedContact({ ...editedContact, sex: e.target.value })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    >
      <option value="">Select Sex</option>
      <option value="Male">Male</option>
      <option value="Female">Female</option>
    </select>
    
    {/* Date of Birth */}
    <label>Date of Birth (YYYY-MM-DD)</label>
    <input
      type="text"
      value={editedContact.dob}
      onChange={(e) =>
        setEditedContact({ ...editedContact, dob: e.target.value })
      }
      onFocus={(e) => (e.target.type = "date")}
      onBlur={(e) => (e.target.type = "text")}
      style={{ width: "100%", padding: "8px", marginBottom: "10px" }}
    />
    
    {/* Religion */}
    <label>Religion</label>
    <select
      value={editedContact.religion}
      onChange={(e) =>
        setEditedContact({ ...editedContact, religion: e.target.value })
      }
      style={{ width: "100%", padding: "8px", marginBottom: "20px" }}
    >
      <option value="">Select Religion</option>
      <option value="Christian">Christian</option>
      <option value="Islamic">Islamic</option>
    </select>
    
    {/* Buttons */}
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <button
        onClick={() => setEditContactVisible(false)}
        style={{
          backgroundColor: "red",
          border: "none",
          padding: "10px 15px",
          cursor: "pointer",
        }}
      >
        Cancel
      </button>
      <button
        onClick={updateContact}
        disabled={isAddingContact}
        style={{
          backgroundColor: "#28a745",
          color: "white",
          border: "none",
          padding: "10px 15px",
          cursor: "pointer",
        }}
      >
        {isAddingContact ? "Please wait..." : "Save Changes"}
      </button>
        </div>
      </div>
    </div>
  </div>
)}

    </div>
  );
}

export default Contacts;
