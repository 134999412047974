import React, { useState, useEffect } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { urls } from './config.dev';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faPrint, faChartLine, faCrown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import './Performance.css';
import Topbar from './Topbar'; // Import the Topbar component
import MissingDrugs from './MissingDrugs'; 
import PTmodal from './PTmodal';  // Import the PTmodal component
import DetailedPerformanceModal from './DetailedPerformanceModal';  

// Register components
// Register components
ChartJS.register(ArcElement, Tooltip, Legend);

function getStartOfMonth() {
  const today = new Date();
  const start = new Date(Date.UTC(today.getFullYear(), today.getMonth(), 1));
  return new Intl.DateTimeFormat('en-CA', {
    timeZone: 'Africa/Kampala',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(start);
}

function getEndOfMonth() {
  const today = new Date();
  const end = new Date(Date.UTC(today.getFullYear(), today.getMonth() + 1, 0));
  return new Intl.DateTimeFormat('en-CA', {
    timeZone: 'Africa/Kampala',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(end);
}




function Performance() {
  const [performanceData, setPerformanceData] = useState([]);
  const [detailedData, setDetailedData] = useState([]);
  const [message, setMessage] = useState('');
  const [startDate, setStartDate] = useState(getStartOfMonth());
  const [endDate, setEndDate] = useState(getEndOfMonth());
  const [selectedSection, setSelectedSection] = useState('Overall');
  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [dataAvailable, setDataAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [loadingEmployee, setLoadingEmployee] = useState('');
  const [loadingSection, setLoadingSection] = useState(false);
  const navigate = useNavigate();
  const [loadingPrint, setLoadingPrint] = useState(false);
  const [isPTModalOpen, setIsPTModalOpen] = useState(false); 
  const [isDetailedPerformanceModalOpen, setIsDetailedPerformanceModalOpen] = useState(false); 
  const [selectedEmployee2, setSelectedEmployee2] = useState(null);

  useEffect(() => {
    const token = getTokenFromUrlOrLocalStorage();
    performSecurityCheck(token);
  }, [startDate, endDate, selectedSection]);

  const getTokenFromUrlOrLocalStorage = () => {
    const params = new URLSearchParams(window.location.search);
    const tokenFromUrl = params.get('token');
    return tokenFromUrl || localStorage.getItem('token');
  };
  // Define urlToken using the function
const urlToken = getTokenFromUrlOrLocalStorage();

  const performSecurityCheck = async (token) => {
    try {
      const securityResponse = await fetch(urls.security, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token }),
      });

      if (securityResponse.ok) {
        const securityData = await securityResponse.json();
        if (securityData.message === 'Session valid') {
          fetchPerformanceData(token);
        } else if (securityData.error === 'Session expired') {
          navigate(`/dashboard?token=${securityData.clinic_session_token}`);
        } else {
          navigate('/login');
        }
      } else {
        throw new Error('Failed to perform security check');
      }
    } catch (error) {
      console.error('Error performing security check:', error);
      navigate('/login');
    }
  };

  const fetchPerformanceData = async (token) => {
    try {
      setLoading(true);
      setLoadingSection(true);
      const response = await fetch(urls.fetchperformance, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          token,
          startDate,
          endDate,
          section: selectedSection,
        }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
        setDataAvailable(false);
      } else {
        data.sort((a, b) => b.percentage - a.percentage);
        setPerformanceData(data);
        setSelectedEmployee(data[0]?.employee_name || '');
        fetchDetailedPerformance(data[0]?.employee_name || '', token);
        setMessage('');
        setDataAvailable(true);
      }
    } catch (error) {
      console.error('Error fetching performance data:', error);
    } finally {
      setLoading(false);
      setLoadingSection(false);
    }
  };

  const fetchDetailedPerformance = async (employeeName, token) => {
    try {
      setLoadingEmployee(employeeName);
      const response = await fetch(urls.detailedperformance, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ employeeName, token }),
      });

      const data = await response.json();

      if (data.error) {
        setMessage(data.error);
      } else {
        const groupedData = data.reduce((acc, item) => {
          const date = item.Date;
          if (!acc[date]) {
            acc[date] = [];
          }
          acc[date].push(item);
          return acc;
        }, {});

        const sortedDates = Object.keys(groupedData).sort((a, b) => new Date(b) - new Date(a));

        const sortedData = sortedDates.map(date => {
          const dayOfWeek = new Date(date).toLocaleDateString('en-US', { weekday: 'long' });
          return {
            date,
            dayOfWeek,
            items: groupedData[date],
          };
        });

        setDetailedData(sortedData);
        setMessage('');
      }
    } catch (error) {
      console.error('Error fetching detailed performance:', error);
    } finally {
      setLoadingEmployee('');
    }
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleSectionChange = (section) => {
    setSelectedSection(section);
    const token = getTokenFromUrlOrLocalStorage();
    fetchPerformanceData(token);
  };

  const handleEmployeeClick = (employeeName) => {
    setSelectedEmployee(employeeName);
    const token = getTokenFromUrlOrLocalStorage();
    fetchDetailedPerformance(employeeName, token);
  };

  const pieChartData = {
    labels: performanceData.map(item => item.employee_name),
    datasets: [
      {
        data: performanceData.map(item => item.percentage),
        backgroundColor: performanceData.map((_, index) => `hsl(${(index * 360) / performanceData.length}, 100%, 50%)`),
      }
    ]
  };

  const handlePrintRanking = async () => {
    try {
        setLoadingPrint(true);
        const token = getTokenFromUrlOrLocalStorage();
        const rankingData = performanceData.map(item => ({
            name: item.employee_name,
            percentage: item.percentage,
        }));

        const response = await fetch(urls.printRanking, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                token,
                rankings: rankingData,
                startDate,  // Add startDate to payload
                endDate,    // Add endDate to payload
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch PDF');
        }

        const pdfBlob = await response.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);
        const pdfWindow = window.open(pdfUrl);

        if (!pdfWindow) {
            alert('Please allow popups for this website');
        }
    } catch (error) {
        console.error('Error printing ranking:', error);
    } finally {
        setLoadingPrint(false);
    }
};
// Function to open the modal
const handleOpenPTModal = () => {
  setIsPTModalOpen(true);
};

// Function to close the modal
const handleClosePTModal = () => {
  setIsPTModalOpen(false);
};
// Function to open the modal
const handleOpenDetailedPerformanceModal = (employeeName) => {
  console.log("Opening modal for:", employeeName); // Debugging
  setSelectedEmployee2(employeeName);
  setIsDetailedPerformanceModalOpen(true);
};

// Function to close the modal
const handleCloseDetailedPerformanceModal = () => {
  setIsDetailedPerformanceModalOpen(false);
};
return (
  <div id="root" className="performance">
    <Topbar token={urlToken} />
    <MissingDrugs token={urlToken} />
    <div className="left-container performance">
      <div className="top-container performance">
        <h1>Employee Participation expressed as a percentage</h1>
        <div className="date-picker-container performance">
          <label htmlFor="startDateInput">From:</label>
          <input
            type="date"
            id="startDateInput"
            value={startDate}
            onChange={handleStartDateChange}
          />
          <label htmlFor="endDateInput">To:</label>
          <input
            type="date"
            id="endDateInput"
            value={endDate}
            onChange={handleEndDateChange}
          />
        </div>
        <div className="section-buttons performance">
          {[
            "Overall",
            "Triage",
            "Doctors Room",
            "Dispensary",
            "Store",
            "DrugAdministration",
            "Cashier",
            "Laboratory",
            "Radiology",
            "Family Planning",
            "ANC",
            "Deliveries",
            "Client Feedbacks",
          ].map((section) => (
            <button
              key={section}
              className={selectedSection === section ? "active" : ""}
              onClick={() => handleSectionChange(section)}
            >
              {loadingSection && selectedSection === section ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                section
              )}
            </button>
          ))}
        </div>
      </div>
      <div className="performance-summary performance">
        <h2>Detailed summary of employee participation with participation notes</h2>
        <div className="names-list performance">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            performanceData.map((item, index) => (
              <button
                key={item.employee_name}
                className={`employee-button ${
                  selectedEmployee === item.employee_name ? "active" : ""
                }`}
                onClick={() => handleEmployeeClick(item.employee_name)}
              >
                {loadingEmployee === item.employee_name ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>
                    {index === 0 && (
                      <FontAwesomeIcon icon={faCrown} className="crown-icon performance" />
                    )}
                    {item.employee_name}
                  </>
                )}
              </button>
            ))
          )}
        </div>
        <div className="details-container performance">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            detailedData.map((group) => (
              <div key={group.date}>
                <h3>{`${group.dayOfWeek}, ${group.date}`}</h3>
                {group.items.map((item, index) => (
                  <p key={index}>
                    {item.time ? `At ${item.time} ` : ""}
                    {item.Sentence}
                  </p>
                ))}
              </div>
            ))
          )}
        </div>
      </div>
    </div>
    <div
      className="right-container performance"
      style={{
        overflowY: "auto",
        maxHeight: "100vh",
      }}
    >
      {dataAvailable ? (
        <>
          <Doughnut data={pieChartData} />
          <div className="pie-chart-title">
            <h2>{selectedSection} Performance</h2>
          </div>
          <div className="employee-performance-list">
            {performanceData.map((item) => (
              <div
                key={item.employee_name}
                onClick={() => handleOpenDetailedPerformanceModal(item.employee_name)}
                className="cursor-pointer hover:bg-gray-200 p-2 rounded"
              >
                <strong>{item.employee_name}:</strong> {item.percentage}%
              </div>
            ))}
          </div>
          {isDetailedPerformanceModalOpen && (
            <DetailedPerformanceModal
              employeeName={selectedEmployee2}
              onClose={handleCloseDetailedPerformanceModal}
              startDate={startDate}
              endDate={endDate}
              token={urlToken}
            />
          )}
        </>
      ) : (
        <p>{message}</p>
      )}
     <div className="print-ranking-container" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
  <button
    className="print-ranking-button"
    onClick={handlePrintRanking}
    disabled={loadingPrint}
    style={{
      backgroundColor: 'blue', // Brighter blue
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      fontSize: '16px',
      borderRadius: '0px',
      cursor: loadingPrint ? 'not-allowed' : 'pointer',
      transition: 'background-color 0.3s ease',
      width: '48%', // Slightly reduced width for both buttons to fit side by side
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px', // Space between icon and text
    }}
    onTouchStart={(e) => e.preventDefault()} // Ensures it works on touchscreens
  >
    <FontAwesomeIcon icon={faPrint} />
    {loadingPrint ? <FontAwesomeIcon icon={faSpinner} spin /> : "Print Performance Ranking"}
  </button>
  
  <button
    className="see-performance-trends-button"
    onClick={handleOpenPTModal}
    style={{
      backgroundColor: 'green', // Brighter green
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      fontSize: '16px',
      borderRadius: '0px',
      cursor: 'pointer',
      transition: 'background-color 0.3s ease',
      width: '48%', // Same width as the print button
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px', // Space between icon and text
      marginLeft: '10px', // Slight space between buttons
    }}
    onTouchStart={(e) => e.preventDefault()} // Ensures it works on touchscreens
  >
    <FontAwesomeIcon icon={faChartLine} />
    See Performance Trends
  </button>
  </div>
      {isPTModalOpen && (
        <PTmodal isOpen={isPTModalOpen} onClose={handleClosePTModal} token={urlToken} />
      )}
    </div>
</div>
 
);

}

export default Performance;
