import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faLightbulb, faHandsHelping, faChartLine, faCalendarAlt, faCode, faHeartbeat } from '@fortawesome/free-solid-svg-icons';
import clementImg from './images/cledd.png';
import jennyImg from './images/jenny.jpg';
import './AboutUs.css';

const AboutUs = () => {
  return (
    <div className="about-us-page">
      {/* Hero Section */}
      <section className="about-us-hero">
        <h1 className="about-us-title">Our Story</h1>
        <p className="about-us-subtitle">How two healthcare professionals turned frustration into innovation</p>
        <div className="about-us-badge">
          <FontAwesomeIcon icon={faCalendarAlt} className="about-us-icon" />
          <span>Founded April 1, 2024</span>
        </div>
      </section>

      {/* Origin Story */}
      <section className="about-us-origin">
        <h2 className="about-us-section-title">Why We Built ClinicProSystem</h2>
        <div className="about-us-origin-content">
          <div className="about-us-origin-text">
            <p>In early 2024, while working in a busy urban clinic, Clement and Jenny witnessed firsthand the administrative chaos that plagues healthcare facilities. Missing files, forgotten appointments, repetitive tiring activities, poor statistical data collection, poor aftersale services and uncollected payments weren't just inconveniences—they were barriers to quality patient care.</p>
            <p>After months of frustration watching these systemic issues waste staff time and compromise patient outcomes, we decided to build a solution that would address the root causes rather than just the symptoms.</p>
            <div className="about-us-milestone">
              <FontAwesomeIcon icon={faCode} className="about-us-milestone-icon" />
              <span>First line of code written: March 2024</span>
            </div>
            <div className="about-us-milestone">
              <FontAwesomeIcon icon={faHeartbeat} className="about-us-milestone-icon" />
              <span>First clinic implementation: June 2024</span>
            </div>
            <div className="about-us-milestone">
              <FontAwesomeIcon icon={faChartLine} className="about-us-milestone-icon" />
              <span>Official company launch: April 2025</span>
            </div>
          </div>
        </div>
      </section>

      {/* Founders Section */}
      <section className="about-us-founders">
        <h2 className="about-us-section-title">The Faces Behind ClinicProSystem</h2>
        <div className="about-us-founders-grid">
          <div className="about-us-founder-card">
            <img src={clementImg} alt="Ddungu Clement" className="about-us-founder-img" />
            <h3 className="about-us-founder-name">Ddungu Clement</h3>
            <p className="about-us-founder-role">Founder & Lead Developer</p>
            <p className="about-us-founder-bio">
              With a background in both healthcare and software engineering, Clement brings a unique perspective to clinic management solutions. His experience working in understaffed clinics showed him exactly where technology could make the biggest impact.
            </p>
            <p className="about-us-founder-quote">
              "I watched good clinics struggle not because of poor medical care, but because of preventable administrative failures. That's what ClinicProSystem was born to fix."
            </p>
          </div>
          <div className="about-us-founder-card">
            <img src={jennyImg} alt="Kisaakye Jenny" className="about-us-founder-img" />
            <h3 className="about-us-founder-name">Kisaakye Jenny</h3>
            <p className="about-us-founder-role">Co-Founder & Operations Lead</p>
            <p className="about-us-founder-bio">
              Jenny's front-line experience as a clinic cashier gave her deep insight into the financial and communication challenges clinics face daily. She ensures our solutions work in real-world conditions.
            </p>
            <p className="about-us-founder-quote">
              "When you're juggling patient care and paperwork, you need systems that adapt to you—not the other way around. That's our design philosophy."
            </p>
          </div>
        </div>
      </section>

      {/* Development Journey */}
      <section className="about-us-journey">
        <h2 className="about-us-section-title">Our Development Journey</h2>
        <div className="about-us-timeline">
          <div className="about-us-timeline-item">
            <div className="about-us-timeline-date">Early 2024</div>
            <div className="about-us-timeline-content">
              <h3>The Breaking Point</h3>
              <p>After yet another chaotic day at the clinic with missed appointments and billing errors, we sketched the first concepts for what would become ClinicProSystem.</p>
            </div>
          </div>
          <div className="about-us-timeline-item">
            <div className="about-us-timeline-date">Mid 2024</div>
            <div className="about-us-timeline-content">
              <h3>Prototype Testing</h3>
              <p>We implemented a basic version in our own clinic, refining features based on daily staff feedback and real-world challenges.</p>
            </div>
          </div>
          <div className="about-us-timeline-item">
            <div className="about-us-timeline-date">Late 2024</div>
            <div className="about-us-timeline-content">
              <h3>First External Clients</h3>
              <p>Two clinics adopted our system after seeing our improved efficiency and patient satisfaction metrics.</p>
            </div>
          </div>
          <div className="about-us-timeline-item">
            <div className="about-us-timeline-date">April 2025</div>
            <div className="about-us-timeline-content">
              <h3>Official Launch</h3>
              <p>Incorporated as a company and began offering ClinicProSystem to clinics nationwide.</p>
            </div>
          </div>
        </div>
      </section>

      {/* Special Thanks Section */}
      <section className="about-us-thanks">
        <h2 className="about-us-section-title">Special Thanks To Our Early Clients</h2>
        <div className="about-us-thanks-grid">
          <div className="about-us-thankee-card">
            <h3>Madam Getrude</h3>
            <p className="about-us-thankee-role">CEO, LIFESURE MEDICARE</p>
            <p className="about-us-thankee-acknowledgement">
              We are eternally grateful to Madam Getrude for giving us the opportunity to test our early application at LIFESURE MEDICARE. Her facility became our proving ground, allowing us to discover crucial loopholes and make necessary improvements that brought us to where we are today.
            </p>
          </div>
          <div className="about-us-thankee-card">
            <h3>Madam Sarah Kikajjo</h3>
            <p className="about-us-thankee-role">Director, Health Center and Maternity Home</p>
            <p className="about-us-thankee-acknowledgement">
              Our heartfelt thanks to Madam Sarah Namyalo for her trust in us during the earliest days of our application, when we had no proven track record or success stories to showcase. Her faith in our vision gave us the confidence to push forward.
            </p>
          </div>
        </div>
      </section>

      {/* Mission & Values */}
      <section className="about-us-mission">
        <h2 className="about-us-section-title">Our Core Beliefs</h2>
        <div className="about-us-mission-cards">
          <div className="about-us-mission-card">
            <FontAwesomeIcon icon={faUserMd} className="about-us-mission-icon" />
            <h3>Healthcare First</h3>
            <p>We're clinicians first, technologists second. Every feature serves the ultimate goal of better patient outcomes.</p>
          </div>
          <div className="about-us-mission-card">
            <FontAwesomeIcon icon={faLightbulb} className="about-us-mission-icon" />
            <h3>Practical Innovation</h3>
            <p>If a solution doesn't work in a busy Monday morning clinic, it doesn't belong in our system.</p>
          </div>
          <div className="about-us-mission-card">
            <FontAwesomeIcon icon={faHandsHelping} className="about-us-mission-icon" />
            <h3>Partnership Approach</h3>
            <p>We grow by helping our clients succeed, not by locking them into restrictive contracts.</p>
          </div>
        </div>
      </section>

      {/* Achievements */}
      <section className="about-us-achievements">
        <h2 className="about-us-section-title">Our Milestones</h2>
        <div className="about-us-achievements-grid">
          <div className="about-us-achievement-item">
            <div className="about-us-achievement-number">12+</div>
            <div className="about-us-achievement-text">Clinics Transformed</div>
          </div>
          <div className="about-us-achievement-item">
            <div className="about-us-achievement-number">100%</div>
            <div className="about-us-achievement-text">Client Retention</div>
          </div>
          <div className="about-us-achievement-item">
            <div className="about-us-achievement-number">40%</div>
            <div className="about-us-achievement-text">Average Reduction in Missed Appointments</div>
          </div>
          <div className="about-us-achievement-item">
            <div className="about-us-achievement-number">60%</div>
            <div className="about-us-achievement-text">Improved Management of Clinics by over 60 percent</div>
          </div>
        </div>
      </section>

      {/* Future Goals */}
      <section className="about-us-goals">
        <h2 className="about-us-section-title">Where We're Headed</h2>
        <div className="about-us-goals-list">
          <div className="about-us-goal-item">
            <h3>National Expansion</h3>
            <p>Bringing ClinicProSystem to clinics across the country by 2025</p>
          </div>
          <div className="about-us-goal-item">
            <h3>AI Advancements</h3>
            <p>Developing predictive analytics for patient no-shows and treatment outcomes</p>
          </div>
          <div className="about-us-goal-item">
            <h3>Life Wallet</h3>
            <p>Currently, we are working on the Life Wallet android/apple app to help our patients save emergency fund for their unforeseen sicknesses</p>
          </div>
        </div>
      </section>

      {/* Call-to-Action */}
      <section className="about-us-cta">
        <h2 className="about-us-cta-title">Join Our Story</h2>
        <p className="about-us-cta-text">We're always looking to partner with clinics that share our vision for better healthcare through smarter management.</p>
        <button className="about-us-cta-button" onClick={() => window.location.href = '/clinic-registration'}>
          Become Part of Our Journey
        </button>
      </section>
    </div>
  );
};

export default AboutUs;