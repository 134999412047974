import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { urls } from './config.dev';
import Topbar from './Topbar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; 

function LabDashboard2() {
  const [pendingLabTestsCount, setPendingLabTestsCount] = useState(0);
  const [employeeName, setEmployeeName] = useState('');
  const [stats, setStats] = useState({ year: 0, month: 0, week: 0, pending: 0 });
  const [labTestData, setLabTestData] = useState([]);
  const [startDate, setStartDate] = useState(new Date(new Date().getFullYear(), new Date().getMonth(), 1));  
  const [endDate, setEndDate] = useState(new Date()); 
  const [minAge, setMinAge] = useState(0);
  const [maxAge, setMaxAge] = useState(130);
  const navigate = useNavigate();
  
  const params = new URLSearchParams(window.location.search);
  const urlToken = params.get('token');
  useEffect(() => {
    const scrollWidth = document.documentElement.scrollWidth - window.innerWidth;
    window.scrollTo(scrollWidth / 2, 0);
  }, []);
  
  
  useEffect(() => {
    const fetchTokenAndCheckSecurity = async () => {
      try {
        const securityResponse = await fetch(urls.security, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token: urlToken }),
        });

        if (securityResponse.ok) {
          const securityData = await securityResponse.json();

          if (securityData.message === 'Session valid') {
            setEmployeeName(securityData.employee_name);
            fetchLabStats(urlToken); 
            fetchLabTestsData(urlToken, startDate, endDate, minAge, maxAge); 
          } else if (securityData.error === 'Session expired') {
            navigate(`/dashboard?token=${securityData.clinic_session_token}`);
          } else {
            navigate('/login');
          }
        } else {
          throw new Error('Failed to perform security check');
        }
      } catch (error) {
        console.error('Error performing security check:', error);
        navigate('/login');
      }
    };

    fetchTokenAndCheckSecurity();
  }, [navigate, startDate, endDate, minAge, maxAge]);

  const fetchLabStats = async (token) => {
    try {
      const response = await fetch(urls.labtestscount, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ token }),
      });
  
      if (response.ok) {
        const data = await response.json();
        
        setStats({
          year: data.total_year,     
          month: data.total_month,   
          week: data.total_week,     
          today: data.total_today,   
          pending: data.total_pending 
        });
      } else {
        throw new Error('Failed to fetch lab stats');
      }
    } catch (error) {
      console.error('Error fetching lab stats:', error);
    }
  };
  
  const fetchLabTestsData = async (token, startDate, endDate, minAge, maxAge) => {
    try {
      const response = await fetch(urls.labstat2, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          token,
          startDate: startDate.toISOString().split('T')[0],
          endDate: endDate.toISOString().split('T')[0],
          minAge,
          maxAge
        }),
      });
  
      if (response.ok) {
        const data = await response.json();
        const filteredTests = Object.entries(data).filter(([key, test]) => test.total_positive > 0);
        setLabTestData(filteredTests);
      } else {
        throw new Error('Failed to fetch lab test details');
      }
    } catch (error) {
      console.error('Error fetching lab test details:', error);
    }
  };

  const handleRefreshData = () => {
    fetchLabStats(urlToken);
  };
  
  useEffect(() => {
    const intervalId = setInterval(() => {
      handleRefreshData();
    }, 10000); // Runs every 10 seconds (10000 ms)
  
    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array ensures this runs only once when the component mounts
  const handleNavigateToRecentTests = () => {
    navigate(`/labTests?token=${urlToken}`);
  };
  
  return (
    <div style={{
     
      marginTop: '80px'
    }}>
    
        <Topbar token={urlToken} />
{/* Top Cards */}
<div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '50px', marginBottom: '10px' }}>
    {Object.entries(stats)
      .filter(([key]) => key !== 'pending')
      .map(([key, value]) => (
        <div
          key={key}
          style={{
            background: '#ffffff',
            padding: '25px',
            borderRadius: '0px',
            boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
            textAlign: 'center',
            flex: 1,
            margin: '0 15px',
            fontSize: '20px',
            fontWeight: '600',
            color: 'black',
            transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-10px)';
            e.currentTarget.style.boxShadow = '0 12px 24px rgba(0, 0, 0, 0.2)';
          }}
          onMouseLeave={(e) => {
            const target = e.currentTarget;
            if (!target) return; // Prevent error if component unmounts
  
            target.style.transform = 'translateY(2px)'; // Slight bounce before settling
            target.style.transition = 'transform 0.2s ease-out';
  
            setTimeout(() => {
              if (!target) return; // Check if target still exists
              target.style.transform = 'translateY(0)';
            }, 100); // Delay before settling back
  
            target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.1)';
          }}
        >
          <div style={{ color: '#007bff', marginBottom: '10px' }}>{key.toUpperCase()} TESTS</div>
          <div style={{ fontSize: '32px', color: '#333' }}>{value}</div>
        </div>
      ))}
   {/* Pending Tests Card with Conditional Animation */}
   <div
      style={{
        background: '#ffffff',
        padding: '25px',
        borderRadius: '0px',
        boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        flex: 1,
        margin: '0 15px',
        fontSize: '20px',
        fontWeight: '600',
        color: '#ff4500',
        cursor: 'pointer',
        animation: stats.pending === 0 ? 'none' : 'pulse 2s infinite',
        transition: 'transform 0.3s ease, box-shadow 0.3s ease',
      }}
      onClick={() => navigate(`/lab?token=${urlToken}`)}
      onMouseEnter={(e) => {
        e.target.style.transform = 'scale(1.05)';
        e.target.style.boxShadow = '0 10px 20px rgba(0, 0, 0, 0.2)';
      }}
      onMouseLeave={(e) => {
        e.target.style.transform = 'scale(1)';
        e.target.style.boxShadow = '0 6px 12px rgba(0, 0, 0, 0.1)';
      }}
    >
      Waiting Patients
      <div style={{ fontSize: '32px', marginTop: '15px' }}>{stats.pending}</div>
    </div>
  </div>

  
      <div style={{
  backgroundColor: '#fff', // White background
  borderRadius: '0px', // No border radius
  padding: '20px', // Padding for inner spacing
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: for a subtle shadow
  marginBottom: '1px', // Optional: space between sections
}}>
  <h2 style={{ fontSize: '26px', fontWeight: '700', color: '#333', marginBottom: '20px', textAlign: 'center' }}>
    LABORATORY STATISTICS FROM {startDate.toLocaleDateString()} TO {endDate.toLocaleDateString()}, AGE BETWEEN {minAge} AND {maxAge}
  </h2>

  {/* Date Range Picker and Age Range Inputs with Refresh Button */}
  <div style={{ 
    marginBottom: '30px', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    flexWrap: 'wrap' 
  }}>
    <label style={{ marginRight: '15px', fontSize: '16px', color: '#333' }}>Start Date:</label>
    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
    
    <label style={{ marginLeft: '30px', marginRight: '15px', fontSize: '16px', color: '#333' }}>End Date:</label>
    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)} />
    
    <label style={{ marginLeft: '30px', marginRight: '15px', fontSize: '16px', color: '#333' }}>Min Age:</label>
    <input
      type="number"
      value={minAge}
      onChange={(e) => setMinAge(Number(e.target.value))}
      placeholder="Min Age"
      style={{
        marginRight: '30px',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        width: '80px',
      }}
    />
    
    <label style={{ marginRight: '15px', fontSize: '16px', color: '#333' }}>Max Age:</label>
    <input
      type="number"
      value={maxAge}
      onChange={(e) => setMaxAge(Number(e.target.value))}
      placeholder="Max Age"
      style={{
        marginRight: '30px',
        padding: '10px',
        fontSize: '16px',
        border: '1px solid #ddd',
        borderRadius: '5px',
        width: '80px',
      }}
    />
     <button
      onClick={handleNavigateToRecentTests}
      style={{
        padding: '12px 30px',
        backgroundColor: '#007bff',
        color: '#fff',
        border: 'none',
        borderRadius: '1px',
        fontSize: '16px',
        cursor: 'pointer',
        marginLeft: '60px',
        marginTop: '10px', // Optional to add some space between inputs and button
      }}
    >
     Recent Tests done
    </button>
   
  </div>
</div>


      {/* Lab Test Data Table */}
      <div
        style={{
          background: '#ffffff',
          padding: '30px',
          borderRadius: '0px',
          boxShadow: '0 6px 12px rgba(0, 0, 0, 0.1)',
        }}
      >
        <table style={{ width: '100%', borderCollapse: 'collapse', textAlign: 'left', color: '#333' }}>
          <thead>
            <tr style={{ background: '#f4f7fc', color: '#333' }}>
              <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Test Name</th>
              <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Total Tests</th>
              <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Positive Cases</th>
              <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Males Positive</th>
              <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Females Positive</th>
              <th style={{ padding: '15px', borderBottom: '2px solid #ddd' }}>Most Positive Ages</th>
            </tr>
          </thead>
          <tbody>
            {labTestData.map(([testName, test], index) => (
              <tr key={index} style={{ background: index % 2 === 0 ? '#fafbfc' : '#ffffff' }}>
                <td style={{ padding: '15px', borderBottom: '1px solid #ddd' }}>{testName}</td>
                <td style={{ padding: '15px', borderBottom: '1px solid #ddd' }}>{test.total_tests}</td>
                <td style={{ padding: '15px', borderBottom: '1px solid #ddd', color: '#ff4500' }}>
                  {test.total_positive}
                </td>
                <td style={{ padding: '15px', borderBottom: '1px solid #ddd' }}>{test.male_count}</td>
                <td style={{ padding: '15px', borderBottom: '1px solid #ddd' }}>{test.female_count}</td>
                <td style={{ padding: '15px', borderBottom: '1px solid #ddd', whiteSpace: 'nowrap', overflowX: 'auto', minWidth: '200px' }}>
                  {test.most_common_age && test.most_common_age.length > 0 ? test.most_common_age.join(', ') : ''}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
  
      <style>
        {`
          @keyframes pulse {
            0% { transform: scale(1); }
            50% { transform: scale(1.05); }
            100% { transform: scale(1); }
          }
        `}
      </style>
    </div>
  );
  
  
}

export default LabDashboard2;
